

import React, { useState, useEffect } from 'react';
import Map from '../components/Map'
import PulseLoader from "react-spinners/PulseLoader";
import ark from '../images/ark.svg';
import mapcentersvg from '../images/mapcenter.svg';

import '../css/result.css';

import DataTable from 'react-data-table-component'; // https://www.npmjs.com/package/react-data-table-component

export default function Result(props) {

    const [data, setData] = useState([])
    const [tittel, setTittel] = useState("")
    const [totalRows, setTotalRows] = useState(0)
    const [columns, setColumns] = useState([])
    // const [errormessage, setErrormessage] = useState(null)
    const [mapcenter, setMapcenter] = useState([])
    
    useEffect(
      () => {
        // setErrormessage(null)
        try{
          var newresultjson = JSON.parse(props.resultjson)
        }
        catch (e) {
          console.log(e)
        }

        if(newresultjson && newresultjson.features) {
          var temparr = []
          newresultjson.features.forEach(element => {
            element.attributes.geometry = element.geometry
            temparr.push(element.attributes)
          });
          setData(temparr)
          setTittel("Søkeresultat (" + temparr.length + " elementer)")
          setTotalRows(temparr.length)
          if (props.filtersetting === "Naturtyper") {
            setColumns([
            {
              name: 'Områdenavn',
              selector: (row) => row['Områdenavn'],
              sortable: true,
              minWidth: '200px',
              maxWidth: '1500px', //Fill remaining space
            },
            {
              name: 'Naturtype',
              selector: (row) => row['Naturtype'],
              sortable: true,
              maxWidth: '400px',
            },
            {
              name: 'Faktaark',
              cell: row => <a href={row.Faktaark} rel="noreferrer" target="_blank"><img src={ark} alt="arklogo" height="20"/></a>,
              width: '100px',
              center: true,
            },
            {
              name: 'Vis i kart',
              cell: row => <button type="button" className="btn btn-sm btn-link" onClick={() => {settheMapcenter([row.geometry,row['Områdenavn']])}}><img src={mapcentersvg} alt="arklogo" height="20" /></button>,
              width: '100px',
              center: true,
            },
          ]);
          }
          else if (props.filtersetting === "Vern"){
            setColumns([
            {
              name: 'Naturvern Områdenavn',
              selector: (row) => row['NaturvernOmrådenavn'],
              sortable: true,
              minWidth: '200px',
              maxWidth: '800px', //Fill remaining space
            },
            {
              name: 'Område ID',
              selector: (row) => row['Område5kid'],
              sortable: true,
              maxWidth: '180px',
            },
            {
              name: 'Hovedtype',
              selector: (row) => row['Hovedtypebeskrivelse'],
              sortable: true,
              maxWidth: '180px',
            },
            {
              name: 'Kartleggingsenhet',
              selector: (row) => row['Kartleggingsenhetbeskrivelse'],
              sortable: true,
              maxWidth: '180px',
            },
            {
              name: 'Kartleggingsenhetkode',
              selector: (row) => row['Kartleggingsenhetkode'],
              sortable: true,
              maxWidth: '150px',
            },
            {
              name: 'Faktaark',
              cell: row => <a href={row.Faktaark} rel="noreferrer" target="_blank"><img src={ark} alt="arklogo" height="20"/></a>,
              width: '100px',
              center: true,
            },
            {
              name: 'Til NatStat',
              cell: row => <a href={process.env.REACT_APP_NatstatUrl + "/Map/SelectMap?ninid=" + row['Område5kid']} rel="noreferrer" target="_blank" className="natstatlink">NatStat</a>,
              width: '100px',
              center: true,
            },
            {
              name: 'Vis i kart',
              cell: row => <button type="button" className="btn btn-sm btn-link" onClick={() => {settheMapcenter([row.geometry, row['Område5kid']])}}><img src={mapcentersvg} alt="arklogo" height="20"/></button>,
              width: '80px',
              center: true,
            }
          ]);
          }
      }
      else if (newresultjson && newresultjson.error){
        // setErrormessage(JSON.stringify(newresultjson.error))
        //Reset det som ligger der fra før
        setTittel("")
        setTotalRows(0)
        setColumns([])
        setData([])
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.resultjson])

    useEffect( //Clear result when switching tabs
      () => {
        setTittel("")
        setTotalRows(0)
        setColumns([])
        // setErrormessage(null)
        setData([])
    }, [props.filtersetting])

    const toexporturl = (input) => {
        var urlencodedwhere = fixedEncodeURIComponent(props.sql)
        var url = process.env.REACT_APP_URLExcelExport.replace("{0}", input).replace("{1}", props.filtersetting.toLowerCase()).replace("{2}", urlencodedwhere)
        window.open(url);
    }
    const fixedEncodeURIComponent = (str) => {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    }

    const settheMapcenter = (input) => {
      setMapcenter(input)
    }
 
    return (      
      <div className="resultContainer">
        <div>
          <Map sql={props.sql} filtersetting={props.filtersetting} mapcenter={mapcenter}/>
        </div>
        {/* <div className="d-flex justify-content-center">
          <p style={{fontSize:"30px", color:"red"}} >{errormessage}</p>
        </div> */}
        {/* {props.isLoading === false ?
        <p className="bigtext p-2 m-0">{tittel}</p> 
        : ""} */}
        <div className="datatable">
          <DataTable
            title={tittel}
            columns={columns}
            data={data}
            noDataComponent="Ingen data å vise"
            // responsive
            //noHeader
            //fixedHeader	
            //fixedHeaderScrollHeight="300px"
            pagination
            paginationTotalRows={totalRows}
            progressPending={props.isLoading}
            progressComponent={
              <div>
                <p><b>Søker i {props.filtersetting}</b></p>
                <PulseLoader                
                    size={50}
                    color={"#005e5d"}
                    loading={props.isLoading}
                />
              </div>
            }
          />
        </div>
        {/* <p><b>SQL for søket: </b>{props.sql}</p>
        <p><b>URL for filteret: </b><a href={props.filterurl}>{props.filterurl}</a></p> */}
        <p className="m-2"><b>SQL for søket: </b>{props.sql}</p>
        <p className="m-2"><b>URL for filteret: </b><a href={props.filterurl}>{props.filterurl}</a></p>
        <div className="d-flex justify-content-center">
          <button className={props.sql === "" ? "btn btn-primary btn-sm mt-2 mb-2 btn-block w-25 disabled" : "btn btn-primary btn-sm mt-2 mb-2 btn-block w-25"} onClick={e => {toexporturl("table")}}>Eksporter søkeresultat til Excel</button>
        </div>
      </div>          
    );
}

