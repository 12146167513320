

import React from 'react';

import Select, { components } from 'react-select';
import '../css/filter.css';

const customStylesouter = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    padding: 13,
    margin: 0,
    fontSize: 14,
    border: 0,
    outline: 'none',
  }),
  control: (base, state) => ({
    ...base,
    background: 'rgba(0,0,0,0)',
    fontSize: 12,
    
    border: 0,
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  singleValue: (provided, state) => ({
    ...provided,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
  }),
  placeholder: (defaultStyles) => { //'Velg' er placeholder 
    return {
        ...defaultStyles,
    }
  }
}

function FilterSelect(props) {

    return (      
      <div className="col-12 mt-2 column">
        <button className={'btn btn-sm p-1 btn-forward w-100 ' + props.selectstyle} onClick={() => props.setTabIndex(props.tabIndex)}>
            {props.tabselectname}
            <Select 
              styles={customStylesouter} 
              className="w-100"
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
                MenuList: () => null,
                Menu: () => null,
                MultiValueRemove: () => <div className="custommultivalueremove" onClick={(e) => {e.stopPropagation();props.setSelectedFilter(null)}}><components.MultiValueRemove></components.MultiValueRemove></div>
              }}
              onChange={(e) => console.log(e)}
              isMulti
              isClearable={false}
              isSearchable={false}
              placeholder=""
              value={props.selectedFilter && props.selectedFilter.length > 0 ? [{ label: "(" + props.selectedFilter.length + ")", value: null}] : []}>  
            </Select>
            <p className="icon-sm icon-sm-p" >
              <span className="icon-sm icon-forward"/>
            </p>
        </button>
      </div>     
    );
}

export default FilterSelect