

import React, { useState, useEffect } from 'react';
import '../css/filter.css';
import collapseminus from '../images/collapseminus.svg';
import collapseplus from '../images/collapseplus.svg';
import backarrow from '../images/backarrow.svg';
import Search from '../components/Search';
import FilterSelect from '../components/FilterSelect';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import PulseLoader from "react-spinners/PulseLoader";
import Collapsible from 'react-collapsible';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Header from './Header';
import variabler from '../values/variabler';

// import Map from '../components/Map'
import { loadModules } from 'esri-loader';

const Filter = (props) => {
    //const naturtyperurl = "https://kart.miljodirektoratet.no/arcgis/rest/services/naturtyper_nin/MapServer/0"
    const naturtyperurl = "https://arcgis06.miljodirektoratet.no/arcgis/rest/services/faktaark/naturtyper_nin/MapServer/0"
    //const naturtyperurlprosjektomrade = "https://kart.miljodirektoratet.no/arcgis/rest/services/naturtyper_nin/MapServer/1"
    const naturtyperurlprosjektomrade = "https://arcgis06.miljodirektoratet.no/arcgis/rest/services/faktaark/naturtyper_nin/MapServer/1"
    //const vernurl = "https://kart.miljodirektoratet.no/arcgis/rest/services/vern_naturomrader_nin/MapServer/0"
    const vernurl = "https://arcgis06.miljodirektoratet.no/arcgis/rest/services/faktaark/vern_naturomrader_nin/MapServer/0"

    const kartleggingsenheterurl = "https://arcgis06.miljodirektoratet.no/arcgis/rest/services/faktaark/kartleggingsenheter_nin/MapServer/16"
    const ulkmurl = "https://arcgis06.miljodirektoratet.no/arcgis/rest/services/faktaark/kartleggingsenheter_nin/MapServer/17"
    const variablerurl = "https://arcgis06.miljodirektoratet.no/arcgis/rest/services/faktaark/kartleggingsenheter_nin/MapServer/18"

    const [tabIndex, setTabIndex] = useState(0);

    //Filtersetting
    const [filtersetting, setFiltersetting] = useState(null);
    const [filtersettingOption, setFiltersettingOption] = useState(null);

    //Triggere for søk
    const [refreshvern, doRefreshvern] = useState(0);
    const [refreshnaturtyper, doRefreshnaturtyper] = useState(0);

    //Fylke
    const [fylkeListArr, setFylkeListArr] = useState(null);
    const [selectedFilterFylke, setSelectedFilterFylke] = useState(null);

    //Kommune
    const [kommuneListArr, setKommuneListArr] = useState(null);
    const [selectedFilterKommune, setSelectedFilterKommune] = useState(null);

    //Naturvernområde
    const [naturvernomradeListArr, setNaturvernomradeListArr] = useState(null);
    const [selectedFilterNaturvernomrade, setSelectedFilterNaturvernomrade] = useState(null);

    //Prosjektområde/dekningskart 
    const [prosjektomradeListArr, setProsjektomradeListArr] = useState(null);
    const [selectedFilterProsjektomrade, setSelectedFilterProsjektomrade] = useState(null);

    //Kartleggingsenheter
    //Hovedtype gruppe
    const [kartleggingsenheterhovedtypegruppeListArr, setKartleggingsenheterhovedtypegruppeListArr] = useState(null);
    const [selectedFilterKartleggingsenheterhovedtypegruppe, setSelectedFilterKartleggingsenheterhovedtypegruppe] = useState(null);
    //Hovedtype
    const [kartleggingsenheterhovedtypeListArr, setKartleggingsenheterhovedtypeListArr] = useState(null);
    const [selectedFilterKartleggingsenheterhovedtype, setSelectedFilterKartleggingsenheterhovedtype] = useState(null);
    const [kartleggingsenheterhovedtypedisabled, setKartleggingsenheterhovedtypedisabled] = useState(true);
    //Kartleggingsenhet
    const [kartleggingsenheterkartleggingsenhetListArr, setKartleggingsenheterkartleggingsenhetListArr] = useState(null);
    const [selectedFilterKartleggingsenheterkartleggingsenhet, setSelectedFilterKartleggingsenheterkartleggingsenhet] = useState(null);
    const [kartleggingsenheterkartleggingsenhetdisabled, setKartleggingsenheterkartleggingsenhetdisabled] = useState(true);
    //Kode
    const [selectedFilterKartleggingsenheterkode, setSelectedFilterKartleggingsenheterkode] = useState("NA_");

    //Kartleggingsenheter (fritekstfelt)
    const [kartleggingsenheterListArr, setKartleggingsenheterListArr] = useState(null);
    const [selectedFilterKartleggingsenheter, setSelectedFilterKartleggingsenheter] = useState(null);

    //Variabler
    //Gruppe (kun Naturtyper)
    const [variablergruppeListArr, setVariablergruppeListArr] = useState(null);
    const [selectedFilterVariablergruppe, setSelectedFilterVariablergruppe] = useState(null);
    //Tema (kun Naturtyper)
    const [variablertemaListArr, setVariablertemaListArr] = useState(null);
    const [selectedFilterVariablertema, setSelectedFilterVariablertema] = useState(null);
    const [variablertemadisabled, setVariablertemadisabled] = useState(true);
    //Navn (Naturtyper)
    const [variablernavnListArr, setVariablernavnListArr] = useState(null);
    const [selectedFilterVariablernavn, setSelectedFilterVariablernavn] = useState(null);
    const [variablernavndisabled, setVariablernavndisabled] = useState(true);
    //Navn (Vern)
    const [variablernavnListArrVern, setVariablernavnListArrVern] = useState(null);
    const [selectedFilterVariablernavnVern, setSelectedFilterVariablernavnVern] = useState(null);
    // const [variablernavndisabledVern, setVariablernavndisabledVern] = useState(true); //Blir aldri disabled

    //Trinn
    const [variablertrinnListArr, setVariablertrinnListArr] = useState(null);
    const [selectedFilterVariablertrinn, setSelectedFilterVariablertrinn] = useState(null);
    const [variablertrinndisabled, setVariablertrinndisabled] = useState(true);
    //Kode
    const [selectedFilterVariablerkode, setSelectedFilterVariablerkode] = useState(null);
    
    //Variabler (fritekstfelt)
    const [variablerListArr, setVariablerListArr] = useState(null);
    const [selectedFilterVariabler, setSelectedFilterVariabler] = useState(null);

    //ULKM
    //Gruppe
    const [ulkmNiNHovedtypegruppeListArr, setUlkmNiNHovedtypegruppeListArr] = useState(null);
    const [selectedFilterUlkmNiNHovedtypegruppe, setSelectedFilterUlkmNiNHovedtypegruppe] = useState(null);
    //Tema
    const [ulkmNiNHovedtypeListArr, setUlkmNiNHovedtypeListArr] = useState(null);
    const [selectedFilterUlkmNiNHovedtype, setSelectedFilterUlkmNiNHovedtype] = useState(null);
    const [ulkmNiNHovedtypedisabled, setUlkmNiNHovedtypedisabled] = useState(true);
    //Navn
    const [ulkmgradientkodeListArr, setUlkmgradientkodeListArr] = useState(null);
    const [selectedFilterUlkmgradientkode, setSelectedFilterUlkmgradientkode] = useState(null);
    const [ulkmgradientkodedisabled, setUlkmgradientkodedisabled] = useState(true);
    //Trinn
    const [ulkmtrinnListArr, setUlkmtrinnListArr] = useState(null);
    const [selectedFilterUlkmtrinn, setSelectedFilterUlkmtrinn] = useState(null);
    const [ulkmtrinndisabled, setUlkmtrinndisabled] = useState(true);
    //Kode
    const [selectedFilterUlkmkode, setSelectedFilterUlkmkode] = useState(null);

    //ULKM (fritekstfelt)
    const [ulkmListArr, setUlkmListArr] = useState(null);
    const [selectedFilterUlkm, setSelectedFilterUlkm] = useState(null);

    //Kartleggingsår
    const [kartleggingsaarListArr, setKartleggingsaarListArr] = useState(null);
    const [selectedFilterKartleggingsaar, setSelectedFilterKartleggingsaar] = useState(null);

    //Oppdragsgiver
    const [oppdragsgiverListArr, setOppdragsgiverListArr] = useState(null);
    const [selectedFilterOppdragsgiver, setSelectedFilterOppdragsgiver] = useState(null);

    //Oppdragstaker
    const [oppdragstakerListArr, setOppdragstakerListArr] = useState(null);
    const [selectedFilterOppdragstaker, setSelectedFilterOppdragstaker] = useState(null);

    //Areal
    const [ArealnumberFra, setArealnumberFra] = useState(null);
    const [ArealnumberTil, setArealnumberTil] = useState(null);

    //Naturtyper
    const [naturtyperListArr, setNaturtyperListArr] = useState(null);
    const [selectedFilterNaturtyper, setSelectedFilterNaturtyper] = useState(null);

    //Naturtype utvalgskriterier
    const [UK_Truetstate, setUK_Truetstate] = useState("Ingen filter");
    const [UK_NærTruetstate, setUK_NærTruetstate] = useState("Ingen filter");
    const [UK_SpesieltDårligKartlagtstate, setUK_SpesieltDårligKartlagtstate] = useState("Ingen filter");
    const [UK_SentralØkosystemFunksjonstate, setUK_SentralØkosystemFunksjonstate] = useState("Ingen filter");
    const [UK_SeBekrivelseNaturtypestate, setUK_SeBekrivelseNaturtypestate] = useState("Ingen filter");
    const [UK_IngenStatusstate, setUK_IngenStatusstate] = useState("Ingen filter");
    const [UK_Counter, setUK_Counter] = useState(0);

    //Lokalitetskvalitet
    const [lokalitetskvalitetListArr, setLokalitetskvalitetListArr] = useState(null);
    const [selectedFilterLokalitetskvalitet, setSelectedFilterLokalitetskvalitet] = useState(null);

    //Tilstand
    const [tilstandListArr, setTilstandListArr] = useState(null);
    const [selectedFilterTilstand, setSelectedFilterTilstand] = useState(null);

    //Naturmangfold
    const [naturmangfoldListArr, setNaturmangfoldListArr] = useState(null);
    const [selectedFilterNaturmangfold, setSelectedFilterNaturmangfold] = useState(null);

    //Hovedøkosystem
    const [hovedokosystemListArr, setHovedokosystemListArr] = useState(null);
    const [selectedFilterHovedokosystem, setSelectedFilterHovedokosystem] = useState(null);

    //Merknadsfelt
    const [Merknadsfelt, setMerknadsfelt] = useState(null);

    const [selectRefk, setselectRefk] = useState(null);
    const [selectRefv, setselectRefv] = useState(null);
    const [selectRefu, setselectRefu] = useState(null);

    const [filterIsLoading, setFilterIsLoading] = useState(false);

    const statechange = (setstatefunc, event) => {
        if (event && setstatefunc) {
            setstatefunc(event);      
        }
        else {        
            setstatefunc(null);                        
        }
    }
    const statechangelist = (setstatefunc, currentstate, event, object) => {
      if (event && setstatefunc) {
        if (!currentstate || currentstate.length === 0) {
          setstatefunc([object]);
        }
        else {
          var currentstate2 = currentstate.filter(item => item.value !== object.value); //Fallback to avoid double value
          if (event.target.checked === false) { //removed object
            setstatefunc(currentstate2);
          }
          else {
            currentstate2.push(object); //add object
            setstatefunc(currentstate2);
          }
        }
      }
      else {  
        setstatefunc(null);
      }
    }
    const statefilter = (setstatefunc, event) => {
      if (event && setstatefunc && event.value !== null) {
        setstatefunc(event);
        setFiltersetting(event.value);
      }
      else {        
        setstatefunc(null);
        setFiltersetting(null);                      
      }
    }
    const statechangetext = (setstatefunc, event) => {
        if (event && setstatefunc) {
          if (event.target.type === "checkbox"){
            console.log(event)
            if (event.target.checked) {
              setstatefunc("Ja");
            }
            else {
              setstatefunc("Ingen filter");
            }
          }
          else {
            setstatefunc(event.target.value);
          }

        }
        else {        
            setstatefunc(null);                        
        }
    }
    const updatefromfilter = (filter, filterfunc, isStringReplace, isInt) => {
        if (filter) {
          var prop = filter.split(",")
          var proparray = []
          prop.forEach(element => {
            if (isStringReplace) {
              proparray.push({label: decodeURI(element.replaceAll('_', ' ')), value: decodeURI(element.replaceAll('_', ' '))})
            }
            else if (isInt) {
              proparray.push({label: parseInt(element), value: parseInt(element)})
            }
            else {
              proparray.push({label: decodeURI(element), value: decodeURI(element)})
            }
          });
          filterfunc(proparray)
        }
    }
    const emptyentirefilter = () => { //Nullstill hele filteret
      setSelectedFilterFylke(null)
      setSelectedFilterKommune(null)
      setSelectedFilterProsjektomrade(null)
      setSelectedFilterNaturvernomrade(null)
      setSelectedFilterKartleggingsenheterkode("NA_") //Default er "NA_"
      setSelectedFilterKartleggingsenheter(null)
      setSelectedFilterVariablerkode(null)
      setSelectedFilterVariabler(null)
      setSelectedFilterUlkmkode(null)
      setSelectedFilterUlkm(null)
      setSelectedFilterKartleggingsaar(null) 
      setSelectedFilterOppdragsgiver(null)
      setSelectedFilterOppdragstaker(null)
      setSelectedFilterLokalitetskvalitet(null) 
      setSelectedFilterTilstand(null)
      setSelectedFilterNaturmangfold(null) 
      setSelectedFilterHovedokosystem(null)
      setArealnumberFra(null)
      if (document.getElementById("arealnumberfraid") && document.getElementById("arealnumberfraid").value) {
        document.getElementById("arealnumberfraid").value = null;
      }
      setArealnumberTil(null)
      if (document.getElementById("arealnumbertilid") && document.getElementById("arealnumbertilid").value) {
        document.getElementById("arealnumbertilid").value = null;
      }
      // setArealnumber(null)
      // setArealmoreorless(null)
      setSelectedFilterNaturtyper(null)
      setUK_Truetstate("Ingen filter")
      setUK_NærTruetstate("Ingen filter")
      setUK_SpesieltDårligKartlagtstate("Ingen filter")
      setUK_SentralØkosystemFunksjonstate("Ingen filter")
      setUK_SeBekrivelseNaturtypestate("Ingen filter")
      setUK_IngenStatusstate("Ingen filter")
      setMerknadsfelt(null);
      if (document.getElementById("merknadsfeltid") && document.getElementById("merknadsfeltid").value) {
        document.getElementById("merknadsfeltid").value = null;
      }
    }
    useEffect(() => {
      if (props.filterurl.filtersetting && props.filterurl.filtersetting.toLowerCase() === "naturtyper") {
        setFiltersetting("Naturtyper")
        setFiltersettingOption({ label: "Naturtyper - Miljødirektoratets instruks", value: "Naturtyper"})
        //Fylke
        updatefromfilter(props.filterurl.fylke, setSelectedFilterFylke, true)
        //Kommune
        updatefromfilter(props.filterurl.kommune, setSelectedFilterKommune, true)
        //Prosjektområde
        updatefromfilter(props.filterurl.prosjektomrade, setSelectedFilterProsjektomrade, true)
        //Kartleggingsenheter
        updatefromfilter(props.filterurl.kartleggingsenheter, setSelectedFilterKartleggingsenheter, false, false)
        //Variabler
        updatefromfilter(props.filterurl.variabler, setSelectedFilterVariabler, false, false)
        //Kartleggingsår
        updatefromfilter(props.filterurl.kartleggingsaar, setSelectedFilterKartleggingsaar, false, true)
        //Oppdragsgiver
        updatefromfilter(props.filterurl.oppdragsgiver, setSelectedFilterOppdragsgiver, true)
        //Oppdragstaker
        updatefromfilter(props.filterurl.oppdragstaker, setSelectedFilterOppdragstaker, true)
        //ArealFra
        if (props.filterurl.arealfra) {
          setArealnumberFra(props.filterurl.arealfra)
        }
        //ArealTil
        if (props.filterurl.arealtil) {
          setArealnumberTil(props.filterurl.arealtil)
        }
        //Naturtyper
        updatefromfilter(props.filterurl.naturtyper, setSelectedFilterNaturtyper, false, false)
        //UK_Truet
        if (props.filterurl.UK_Truet) {
          setUK_Truetstate(props.filterurl.UK_Truet)
        }
        //UK_NærTruet
        if (props.filterurl.UK_NaerTruet) {
          setUK_NærTruetstate(props.filterurl.UK_NaerTruet)
        }
        //UK_SpesieltDårligKartlagt
        if (props.filterurl.UK_SpesieltDaarligKartlagt) {
          setUK_SpesieltDårligKartlagtstate(props.filterurl.UK_SpesieltDaarligKartlagt)
        }
        //UK_SentralØkosystemFunksjon
        if (props.filterurl.UK_SentralOekosystemFunksjon) {
          setUK_SentralØkosystemFunksjonstate(props.filterurl.UK_SentralOekosystemFunksjon)
        }
        //UK_SeBekrivelseNaturtype
        if (props.filterurl.UK_SeBekrivelseNaturtype) {
          setUK_SeBekrivelseNaturtypestate(props.filterurl.UK_SeBekrivelseNaturtype)
        }
        //UK_IngenStatus
        if (props.filterurl.UK_IngenStatus) {
          setUK_IngenStatusstate(props.filterurl.UK_IngenStatus)
        }
        //Lokalitetskvalitet
        updatefromfilter(props.filterurl.lokalitetskvalitet, setSelectedFilterLokalitetskvalitet, false, true)
        //Tilstand
        updatefromfilter(props.filterurl.tilstand, setSelectedFilterTilstand, false, true)
        //Naturmangfold
        updatefromfilter(props.filterurl.naturmangfold, setSelectedFilterNaturmangfold, false, true)
        //Hovedøkosystem
        updatefromfilter(props.filterurl.hovedokosystem, setSelectedFilterHovedokosystem, true)
      }
      else if (props.filterurl.filtersetting && props.filterurl.filtersetting.toLowerCase() === "vern") {
        setFiltersetting("Vern")
        setFiltersettingOption({ label: "Natur i Verneområder (NiN)", value: "Vern"})
        //Fylke
        updatefromfilter(props.filterurl.fylke, setSelectedFilterFylke, true)
        //Kommune
        updatefromfilter(props.filterurl.kommune, setSelectedFilterKommune, true)
        //Naturvernområde
        updatefromfilter(props.filterurl.naturvernomrade, setSelectedFilterNaturvernomrade, true)
        //Kartleggingsenheter
        updatefromfilter(props.filterurl.kartleggingsenheter, setSelectedFilterKartleggingsenheter, false, false)
        //Variabler
        updatefromfilter(props.filterurl.variabler, setSelectedFilterVariabler, false, false)
        //Ulkm
        updatefromfilter(props.filterurl.ulkm, setSelectedFilterUlkm, false, false)
        //Oppdragstaker
        updatefromfilter(props.filterurl.oppdragstaker, setSelectedFilterOppdragstaker, true)
        //ArealFra
        if (props.filterurl.arealfra) {
          setArealnumberFra(props.filterurl.arealfra)
        }
        //ArealTil
        if (props.filterurl.arealtil) {
          setArealnumberTil(props.filterurl.arealtil)
        }        
        //Merknadsfelt
        if (props.filterurl.merknadsfelt) {
          setMerknadsfelt(props.filterurl.merknadsfelt)
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterurl])
    useEffect(() => {
      if(selectedFilterKartleggingsenheterhovedtypegruppe){//Hovedtypegruppe har akkurat blitt valgt
        updatekodek()
        loadModules(
          [
            "esri/rest/support/Query", "esri/rest/query" //query unngår på magisk vis CORS-problematikk
          ], { css: true })
        .then((
          [
            Query,
            query         
          ]) => {
            var querykartleggingsenheterh = new Query();
            querykartleggingsenheterh.returnGeometry = false;
            querykartleggingsenheterh.outFields = "hovedtype,hovedtypebeskrivelse";
            querykartleggingsenheterh.where = "hovedtypegruppe='" + selectedFilterKartleggingsenheterhovedtypegruppe.value + "'";
            querykartleggingsenheterh.returnDistinctValues = true;
            query.executeQueryJSON(kartleggingsenheterurl, querykartleggingsenheterh).then(result => {
              var temparr = []
              result.features.forEach(element => {
                temparr.push({ label: element.attributes.hovedtype + " (" + element.attributes.hovedtypebeskrivelse + ")", value: element.attributes.hovedtype })
              });
              setSelectedFilterKartleggingsenheterhovedtype(null); //Nullstill i tilfellet noe er valgt allerede
              setSelectedFilterKartleggingsenheterkartleggingsenhet(null); //Nullstill i tilfellet noe er valgt allerede

              setKartleggingsenheterhovedtypeListArr(temparr);
              setKartleggingsenheterkartleggingsenhetListArr(null); //Nullstill i tilfellet noe er valgt allerede

              setKartleggingsenheterkartleggingsenhetdisabled(true); //Disable
              setKartleggingsenheterhovedtypedisabled(false); //Enable

            })
            .catch(ex => {
                console.log(ex);
            });
          });
      }
      else { //Feltet har akkurat blitt nullstilt
        setSelectedFilterKartleggingsenheterhovedtype(null); //Nullstill i tilfellet noe er valgt allerede
        setSelectedFilterKartleggingsenheterkartleggingsenhet(null); //Nullstill i tilfellet noe er valgt allerede

        setKartleggingsenheterkartleggingsenhetListArr(null); //Nullstill i tilfellet noe er valgt allerede
        setKartleggingsenheterhovedtypeListArr(null);

        setKartleggingsenheterkartleggingsenhetdisabled(true); //Disable
        setKartleggingsenheterhovedtypedisabled(true);

        updatekodek();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterKartleggingsenheterhovedtypegruppe])
    useEffect(() => {
      if(selectedFilterKartleggingsenheterhovedtype){//Hovedtype har akkurat blitt valgt
        updatekodek()
        loadModules(
          [
            "esri/rest/support/Query", "esri/rest/query" //query unngår på magisk vis CORS-problematikk
          ], { css: true })
        .then((
          [
            Query,
            query         
          ]) => {
            var whereclause = "hovedtypegruppe='" + selectedFilterKartleggingsenheterhovedtypegruppe.value + "'"
            if (selectedFilterKartleggingsenheterhovedtype.value !== null) {
              whereclause += " AND hovedtype='" + selectedFilterKartleggingsenheterhovedtype.value + "'"
            }
            else {
              whereclause += " AND hovedtype IS NULL"
            }
            var querykartleggingsenheterk = new Query();
            querykartleggingsenheterk.returnGeometry = false;
            querykartleggingsenheterk.outFields = "kartleggingsenhet,kartleggingsenhetbeskrivelse";
            querykartleggingsenheterk.where = whereclause;
            querykartleggingsenheterk.returnDistinctValues = true;
            query.executeQueryJSON(kartleggingsenheterurl,querykartleggingsenheterk).then(result => {
              var temparr = []
              result.features.forEach(element => {
                temparr.push({ label: element.attributes.kartleggingsenhet + " (" + element.attributes.kartleggingsenhetbeskrivelse + ")", value: element.attributes.kartleggingsenhet })
              });
              setSelectedFilterKartleggingsenheterkartleggingsenhet(null); //Nullstill i tilfellet noe er valgt allerede

              setKartleggingsenheterkartleggingsenhetdisabled(false); //Enable

              setKartleggingsenheterkartleggingsenhetListArr(temparr);
            })
            .catch(ex => {
                console.log(ex);
            });
          });
      }
      else { //Feltet har akkurat blitt nullstilt
        setSelectedFilterKartleggingsenheterkartleggingsenhet(null); //Nullstill i tilfellet noe er valgt allerede

        setKartleggingsenheterkartleggingsenhetListArr(null); //Nullstill i tilfellet noe er valgt allerede

        setKartleggingsenheterkartleggingsenhetdisabled(true); //Disable

        updatekodek();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterKartleggingsenheterhovedtype])
    useEffect(() => {
      updatekodek();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterKartleggingsenheterkartleggingsenhet])
    useEffect(() => {
      if(selectedFilterVariablergruppe){//Gruppe har akkurat blitt valgt
        updatekodev()
        loadModules(
          [
            "esri/rest/support/Query", "esri/rest/query" //query unngår på magisk vis CORS-problematikk
          ], { css: true })
        .then((
          [
            Query,
            query         
          ]) => {
            var queryvariablerh = new Query();
            queryvariablerh.returnGeometry = false;
            queryvariablerh.outFields = "tema,temabeskrivelse";
            queryvariablerh.where = "gruppe='" + selectedFilterVariablergruppe.value + "'";
            queryvariablerh.returnDistinctValues = true;
            query.executeQueryJSON(variablerurl,queryvariablerh).then(result => {
              var temparr = []
              result.features.forEach(element => {
                temparr.push({ label: element.attributes.tema + " (" + element.attributes.temabeskrivelse + ")", value: element.attributes.tema })
              });
              setSelectedFilterVariablertema(null); //Nullstill i tilfellet noe er valgt allerede
              setSelectedFilterVariablernavn(null); //Nullstill i tilfellet noe er valgt allerede
              setSelectedFilterVariablertrinn(null); //Nullstill i tilfellet noe er valgt allerede
              
              setVariablertemaListArr(temparr);
              setVariablernavnListArr(null); //Nullstill i tilfellet noe er valgt allerede
              setVariablertrinnListArr(null); //Nullstill i tilfellet noe er valgt allerede

              setVariablertemadisabled(false); //Enable
              setVariablernavndisabled(true);
              setVariablertrinndisabled(true); //Disable
            })
            .catch(ex => {
                console.log(ex);
            });
          });
      }
      else { //Feltet har akkurat blitt nullstilt
        setSelectedFilterVariablertema(null); //Nullstill i tilfellet noe er valgt allerede
        setSelectedFilterVariablernavn(null); //Nullstill i tilfellet noe er valgt allerede
        setSelectedFilterVariablertrinn(null); //Nullstill i tilfellet noe er valgt allerede

        setVariablertemaListArr(null); //Nullstill i tilfellet noe er valgt allerede
        setVariablernavnListArr(null); //Nullstill i tilfellet noe er valgt allerede
        setVariablertrinnListArr(null); //Nullstill i tilfellet noe er valgt allerede

        setVariablertemadisabled(true);
        setVariablernavndisabled(true);
        setVariablertrinndisabled(true); //Disable

        updatekodev();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterVariablergruppe])
    useEffect(() => {
      if(selectedFilterVariablertema){//Tema har akkurat blitt valgt
        updatekodev()
        loadModules(
          [
            "esri/rest/support/Query", "esri/rest/query" //query unngår på magisk vis CORS-problematikk
          ], { css: true })
        .then((
          [
            Query,
            query         
          ]) => {
            var queryvariablerh = new Query();
            queryvariablerh.returnGeometry = false;
            queryvariablerh.outFields = "navn,navnbeskrivelse";
            queryvariablerh.where = "gruppe='" + selectedFilterVariablergruppe.value + "' AND tema='" + selectedFilterVariablertema.value + "'";
            queryvariablerh.returnDistinctValues = true;
            query.executeQueryJSON(variablerurl,queryvariablerh).then(result => {
              var temparr = []
              result.features.forEach(element => {
                temparr.push({ label: element.attributes.navn + " (" + element.attributes.navnbeskrivelse + ")", value: element.attributes.navn })
              });
              setSelectedFilterVariablernavn(null); //Nullstill i tilfellet noe er valgt allerede
              setSelectedFilterVariablertrinn(null); //Nullstill i tilfellet noe er valgt allerede
              
              setVariablernavnListArr(temparr);
              setVariablertrinnListArr(null); //Nullstill i tilfellet noe er valgt allerede

              setVariablernavndisabled(false);
              setVariablertrinndisabled(true); //Disable
            })
            .catch(ex => {
                console.log(ex);
            });
          });
      }
      else { //Feltet har akkurat blitt nullstilt
        setSelectedFilterVariablernavn(null); //Nullstill i tilfellet noe er valgt allerede
        setSelectedFilterVariablertrinn(null); //Nullstill i tilfellet noe er valgt allerede

        setVariablernavnListArr(null); //Nullstill i tilfellet noe er valgt allerede
        setVariablertrinnListArr(null); //Nullstill i tilfellet noe er valgt allerede

        setVariablernavndisabled(true);
        setVariablertrinndisabled(true); //Disable

        updatekodev();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterVariablertema])
    useEffect(() => {
      if(selectedFilterVariablernavn){//Navn har akkurat blitt valgt
        updatekodev()
        loadModules(
          [
            "esri/rest/support/Query", "esri/rest/query" //query unngår på magisk vis CORS-problematikk
          ], { css: true })
        .then((
          [
            Query,
            query         
          ]) => {
            var whereclause = ""
            if (selectedFilterVariablergruppe && selectedFilterVariablergruppe.value !== null) {
              whereclause += "gruppe='" + selectedFilterVariablergruppe.value + "' AND "
            }
            if (selectedFilterVariablertema && selectedFilterVariablertema.value !== null) {
              whereclause += "tema='" + selectedFilterVariablertema.value + "' AND "
            }
            // var whereclause = "gruppe='" + selectedFilterVariablergruppe.value + "' AND tema='" + selectedFilterVariablertema.value + "'" 
            if (selectedFilterVariablernavn.value !== null) {
              whereclause += "navn='" + selectedFilterVariablernavn.value + "'"
            }
            else {
              whereclause += "navn IS NULL"
            }
            var queryvariablerh = new Query();
            queryvariablerh.returnGeometry = false;
            queryvariablerh.outFields = "trinn,trinnbeskrivelse";
            queryvariablerh.where = whereclause;
            queryvariablerh.returnDistinctValues = true;
            query.executeQueryJSON(variablerurl,queryvariablerh).then(result => {
              var temparr = []
              result.features.forEach(element => {
                temparr.push({ label: element.attributes.trinn + " (" + element.attributes.trinnbeskrivelse + ")", value: element.attributes.trinn })
              });
              setSelectedFilterVariablertrinn(null); //Nullstill i tilfellet noe er valgt allerede
              
              setVariablertrinnListArr(temparr); //Nullstill i tilfellet noe er valgt allerede

              setVariablertrinndisabled(false); //Enable
            })
            .catch(ex => {
                console.log(ex);
            });
          });
      }
      else { //Feltet har akkurat blitt nullstilt
        setSelectedFilterVariablertrinn(null); //Nullstill i tilfellet noe er valgt allerede

        setVariablertrinnListArr(null); //Nullstill i tilfellet noe er valgt allerede

        setVariablertrinndisabled(true); //Disable

        updatekodev();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterVariablernavn])
    useEffect(() => {
      updatekodev();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterVariablertrinn])

    useEffect(() => {
      if(selectedFilterVariablernavnVern){//Navn (Vern) har akkurat blitt valgt
        updatekodev()
        loadModules(
          [
            "esri/rest/support/Query", "esri/rest/query" //query unngår på magisk vis CORS-problematikk
          ], { css: true })
        .then((
          [
            Query,
            query         
          ]) => {
            var whereclause = ""
            // if (selectedFilterVariablergruppe && selectedFilterVariablergruppe.value !== null) {
            //   whereclause += "gruppe='" + selectedFilterVariablergruppe.value + "' AND "
            // }
            // if (selectedFilterVariablertema && selectedFilterVariablertema.value !== null) {
            //   whereclause += "tema='" + selectedFilterVariablertema.value + "' AND "
            // }
            // var whereclause = "gruppe='" + selectedFilterVariablergruppe.value + "' AND tema='" + selectedFilterVariablertema.value + "'" 
            if (selectedFilterVariablernavnVern.value !== null) {
              whereclause += "variabelkode LIKE '%" + selectedFilterVariablernavnVern.value + "%'"
            }
            else {
              whereclause += "navn IS NULL"
            }
            var queryvariablerh = new Query();
            queryvariablerh.returnGeometry = false;
            queryvariablerh.outFields = "trinn,trinnbeskrivelse";
            queryvariablerh.where = whereclause;
            queryvariablerh.returnDistinctValues = true;
            query.executeQueryJSON(variablerurl,queryvariablerh).then(result => {
              var temparr = []
              result.features.forEach(element => {
                temparr.push({ label: element.attributes.trinn + " (" + element.attributes.trinnbeskrivelse + ")", value: element.attributes.trinn })
              });
              setSelectedFilterVariablertrinn(null); //Nullstill i tilfellet noe er valgt allerede
              
              setVariablertrinnListArr(temparr); //Nullstill i tilfellet noe er valgt allerede

              setVariablertrinndisabled(false); //Enable
            })
            .catch(ex => {
                console.log(ex);
            });
          });
      }
      else { //Feltet har akkurat blitt nullstilt
        setSelectedFilterVariablertrinn(null); //Nullstill i tilfellet noe er valgt allerede

        setVariablertrinnListArr(null); //Nullstill i tilfellet noe er valgt allerede

        setVariablertrinndisabled(true); //Disable

        updatekodev();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterVariablernavnVern])


    useEffect(() => {
      if(selectedFilterUlkmNiNHovedtypegruppe){//NiNHovedtypegruppe har akkurat blitt valgt
        updatekodeu()
        loadModules(
          [
            "esri/rest/support/Query", "esri/rest/query" //query unngår på magisk vis CORS-problematikk
          ], { css: true })
        .then((
          [
            Query,
            query         
          ]) => {
            var queryUlkmh = new Query();
            queryUlkmh.returnGeometry = false;
            queryUlkmh.outFields = "ninhovedtype";
            queryUlkmh.where = "ninhovedtypegruppe='" + selectedFilterUlkmNiNHovedtypegruppe.value + "'";
            queryUlkmh.returnDistinctValues = true;
            query.executeQueryJSON(ulkmurl,queryUlkmh).then(result => {
              var temparr = []
              result.features.forEach(element => {
                temparr.push({ label: element.attributes.ninhovedtype, value: element.attributes.ninhovedtype })
              });
              setSelectedFilterUlkmNiNHovedtype(null); //Nullstill i tilfellet noe er valgt allerede
              setSelectedFilterUlkmgradientkode(null); //Nullstill i tilfellet noe er valgt allerede
              setSelectedFilterUlkmtrinn(null); //Nullstill i tilfellet noe er valgt allerede
              
              setUlkmNiNHovedtypeListArr(temparr);
              setUlkmgradientkodeListArr(null); //Nullstill i tilfellet noe er valgt allerede
              setUlkmtrinnListArr(null); //Nullstill i tilfellet noe er valgt allerede

              setUlkmNiNHovedtypedisabled(false); //Enable
              setUlkmgradientkodedisabled(true);
              setUlkmtrinndisabled(true); //Disable
            })
            .catch(ex => {
                console.log(ex);
            });
          });
      }
      else { //Feltet har akkurat blitt nullstilt
        setSelectedFilterUlkmNiNHovedtype(null); //Nullstill i tilfellet noe er valgt allerede
        setSelectedFilterUlkmgradientkode(null); //Nullstill i tilfellet noe er valgt allerede
        setSelectedFilterUlkmtrinn(null); //Nullstill i tilfellet noe er valgt allerede

        setUlkmNiNHovedtypeListArr(null); //Nullstill i tilfellet noe er valgt allerede
        setUlkmgradientkodeListArr(null); //Nullstill i tilfellet noe er valgt allerede
        setUlkmtrinnListArr(null); //Nullstill i tilfellet noe er valgt allerede

        setUlkmNiNHovedtypedisabled(true);
        setUlkmgradientkodedisabled(true);
        setUlkmtrinndisabled(true); //Disable

        updatekodeu();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterUlkmNiNHovedtypegruppe])
    useEffect(() => {
      if(selectedFilterUlkmNiNHovedtype){//NiNHovedtype har akkurat blitt valgt
        updatekodeu()
        loadModules(
          [
            "esri/rest/support/Query", "esri/rest/query" //query unngår på magisk vis CORS-problematikk
          ], { css: true })
        .then((
          [
            Query,
            query         
          ]) => {
            var queryUlkmg = new Query();
            queryUlkmg.returnGeometry = false;
            queryUlkmg.outFields = "gradientkode,gradientkodebeskrivelse";
            queryUlkmg.where = "ninhovedtypegruppe='" + selectedFilterUlkmNiNHovedtypegruppe.value + "' AND ninhovedtype='" + selectedFilterUlkmNiNHovedtype.value + "'";
            queryUlkmg.returnDistinctValues = true;
            query.executeQueryJSON(ulkmurl,queryUlkmg).then(result => {
              var temparr = []
              result.features.forEach(element => {
                temparr.push({ label: element.attributes.gradientkode + " (" + element.attributes.gradientkodebeskrivelse + ")", value: element.attributes.gradientkode })
              });
              setSelectedFilterUlkmgradientkode(null); //Nullstill i tilfellet noe er valgt allerede
              setSelectedFilterUlkmtrinn(null); //Nullstill i tilfellet noe er valgt allerede
              
              setUlkmgradientkodeListArr(temparr);
              setUlkmtrinnListArr(null); //Nullstill i tilfellet noe er valgt allerede

              setUlkmgradientkodedisabled(false);
              setUlkmtrinndisabled(true); //Disable
            })
            .catch(ex => {  
                console.log(ex);
            });
          });
      }
      else { //Feltet har akkurat blitt nullstilt
        setSelectedFilterUlkmgradientkode(null); //Nullstill i tilfellet noe er valgt allerede
        setSelectedFilterUlkmtrinn(null); //Nullstill i tilfellet noe er valgt allerede

        setUlkmgradientkodeListArr(null); //Nullstill i tilfellet noe er valgt allerede
        setUlkmtrinnListArr(null); //Nullstill i tilfellet noe er valgt allerede

        setUlkmgradientkodedisabled(true);
        setUlkmtrinndisabled(true); //Disable

        updatekodeu();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterUlkmNiNHovedtype])
    useEffect(() => {
      if(selectedFilterUlkmgradientkode){//Gradientkode har akkurat blitt valgt
        updatekodeu()
        loadModules(
          [
            "esri/rest/support/Query", "esri/rest/query" //query unngår på magisk vis CORS-problematikk
          ], { css: true })
        .then((
          [
            Query,
            query         
          ]) => {
            var queryUlkmt = new Query();
            queryUlkmt.returnGeometry = false;
            queryUlkmt.outFields = "trinn,trinnbeskrivelse";
            queryUlkmt.where = "ninhovedtypegruppe='" + selectedFilterUlkmNiNHovedtypegruppe.value + "' AND ninhovedtype='" + selectedFilterUlkmNiNHovedtype.value + "' AND gradientkode='" + selectedFilterUlkmgradientkode.value + "'";
            queryUlkmt.returnDistinctValues = true;
            query.executeQueryJSON(ulkmurl,queryUlkmt).then(result => {
              var temparr = []
              result.features.forEach(element => {
                temparr.push({ label: element.attributes.trinn + " (" + element.attributes.trinnbeskrivelse + ")", value: element.attributes.trinn })
              });
              setSelectedFilterUlkmtrinn(null); //Nullstill i tilfellet noe er valgt allerede
              
              setUlkmtrinnListArr(temparr); //Nullstill i tilfellet noe er valgt allerede

              setUlkmtrinndisabled(false);
            })
            .catch(ex => {  
                console.log(ex);
            });
          });
      }
      else { //Feltet har akkurat blitt nullstilt
        setSelectedFilterUlkmtrinn(null); //Nullstill i tilfellet noe er valgt allerede

        setUlkmtrinnListArr(null); //Nullstill i tilfellet noe er valgt allerede

        setUlkmtrinndisabled(true); //Disable

        updatekodeu();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterUlkmgradientkode])
    useEffect(() => {
      updatekodeu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterUlkmtrinn])
    useEffect(() => {
      let uniqueCount = [UK_Truetstate,UK_NærTruetstate,UK_SpesieltDårligKartlagtstate,UK_SentralØkosystemFunksjonstate,UK_SeBekrivelseNaturtypestate,UK_IngenStatusstate];
      var count = {};
      uniqueCount.forEach(function(i) { count[i] = (count[i]||0) + 1;});
      setUK_Counter(count["Nei"] + count["Ja"])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UK_Truetstate,UK_NærTruetstate,UK_SpesieltDårligKartlagtstate,UK_SentralØkosystemFunksjonstate,UK_SeBekrivelseNaturtypestate,UK_IngenStatusstate])

    const updatekodek = () => {
      var kode = "NA_";
      if (selectedFilterKartleggingsenheterhovedtypegruppe) {
        kode += selectedFilterKartleggingsenheterhovedtypegruppe.value
      }
      if (selectedFilterKartleggingsenheterhovedtype && selectedFilterKartleggingsenheterhovedtype.value !== null) {
        kode += selectedFilterKartleggingsenheterhovedtype.value
      }
      if (selectedFilterKartleggingsenheterkartleggingsenhet && selectedFilterKartleggingsenheterkartleggingsenhet.value !== null && selectedFilterKartleggingsenheterkartleggingsenhet.value !== '') {
        kode += "-" + selectedFilterKartleggingsenheterkartleggingsenhet.value
      }
      setSelectedFilterKartleggingsenheterkode(kode)
    }

    const updatekodev = () => {
      var kode = "";
      if (selectedFilterVariablergruppe) {
        kode += selectedFilterVariablergruppe.value
      }
      if (selectedFilterVariablertema) {
        kode += selectedFilterVariablertema.value
      }
      if (selectedFilterVariablernavn && selectedFilterVariablernavn.value !== null) {
        kode += "-" + selectedFilterVariablernavn.value
      }
      else if (selectedFilterVariablernavnVern && selectedFilterVariablernavnVern.value !== null) {
        kode += selectedFilterVariablernavnVern.value
      }
      if (selectedFilterVariablertrinn) {
        kode += "_" + selectedFilterVariablertrinn.value
      }
      setSelectedFilterVariablerkode(kode)
    }

    const updatekodeu = () => {
      var kode = "";
      if (selectedFilterUlkmNiNHovedtypegruppe) {
        kode += selectedFilterUlkmNiNHovedtypegruppe.value
      }
      if (selectedFilterUlkmNiNHovedtype) {
        kode += selectedFilterUlkmNiNHovedtype.value
      }
      if (selectedFilterUlkmgradientkode) {
        kode += "-" + selectedFilterUlkmgradientkode.value
      }
      if (selectedFilterUlkmtrinn) {
        kode += "_" + selectedFilterUlkmtrinn.value
      }
      setSelectedFilterUlkmkode(kode)
    } 

    const customStylesfilterSetting = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? '#FFFFFF' : state.isFocused ? '#FFFFFF' : '#337E7D',
        padding: 13,
        margin: 0,
        fontSize: 14,
        border: 0,
        outline: 'none', 
        background: state.isFocused ? '#337E7D' : state.isSelected ? '#40C1AC' : '#E6EFEF',
      }),
      control: (base, state) => ({
        ...base,
        // color: '#FFFFFF',
        background: state.hasValue ? '#337E7D' : '#EBF3F3',
        fontSize: 14,
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: '#FFFFFF',
      }),
      placeholder: (defaultStyles) => { //'Velg' er placeholder 
        return {
            ...defaultStyles,
            color: '#000000',
        }
      }
    }

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'blue' : 'black',
        padding: 20,
      })
    }
    
    const customStylesouter = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        // color: state.isSelected ? '#FFFFFF' : state.isFocused ? '#FFFFFF' : '#337E7D',
        padding: 13,
        margin: 0,
        fontSize: 14,
        border: 0,
        outline: 'none', 
        // background: state.isFocused ? '#337E7D' : state.isSelected ? '#40C1AC' : '#E6EFEF',
      }),
      control: (base, state) => ({
        ...base,
        // color: '#FFFFFF',
        // background: state.hasValue ? '#337E7D' : '#EBF3F3',
        background: 'rgba(0,0,0,0)',
        fontSize: 12,
        
        border: 0,
        // This line disable the blue border
        boxShadow: 'none',
        cursor: 'pointer',
      }),
      singleValue: (provided, state) => ({
        ...provided,
        // color: '#FFFFFF',
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        //textOverflow: "ellipsis",
        //maxWidth: "90%",
        //whiteSpace: "nowrap",
        //overflow: "hidden",
        //display: "initial"
      }),
      placeholder: (defaultStyles) => { //'Velg' er placeholder 
        return {
            ...defaultStyles,
            // color: '#000000',
        }
      }

    }

    const addFilter = (func, defau, input, ref) => {
      console.log("Adding filter...")
      var proparray = []
      if (defau) {
        proparray = defau
      }
      if (proparray.filter(i => input === i.value).length === 0) {
        proparray.push({label: decodeURI(input), value: decodeURI(input)})
        func(proparray)
        if (ref.select) { //Fallback, kan skje ved refresh/ bytte av fane
          ref.select.focus()
          //ref.select.blur()
        }
        else {
          ref.focus()
          //ref.blur()
        }
      }
      else {
        console.log("Denne verdien er allerede i filteret")
      }
    }

    const findfunc = (funcname) => {
      switch (funcname) {
        case "selectedFilterFylke": return [setSelectedFilterFylke, selectedFilterFylke];
        case "selectedFilterKommune": return [setSelectedFilterKommune, selectedFilterKommune];
        case "selectedFilterProsjektomrade": return [setSelectedFilterProsjektomrade, selectedFilterProsjektomrade];
        case "selectedFilterNaturvernomrade": return [setSelectedFilterNaturvernomrade, selectedFilterNaturvernomrade];
        case "selectedFilterKartleggingsenheter": return [setSelectedFilterKartleggingsenheter, selectedFilterKartleggingsenheter];
        case "selectedFilterVariabler": return [setSelectedFilterVariabler, selectedFilterVariabler];
        case "selectedFilterUlkm": return [setSelectedFilterUlkm, selectedFilterUlkm];
        case "selectedFilterKartleggingsaar": return [setSelectedFilterKartleggingsaar, selectedFilterKartleggingsaar]; 
        case "selectedFilterOppdragsgiver": return [setSelectedFilterOppdragsgiver, selectedFilterOppdragsgiver];
        case "selectedFilterOppdragstaker": return [setSelectedFilterOppdragstaker, selectedFilterOppdragstaker];
        case "selectedFilterLokalitetskvalitet": return [setSelectedFilterLokalitetskvalitet, selectedFilterLokalitetskvalitet];
        case "selectedFilterTilstand": return [setSelectedFilterTilstand, selectedFilterTilstand];
        case "selectedFilterNaturmangfold": return [setSelectedFilterNaturmangfold, selectedFilterNaturmangfold]; 
        case "selectedFilterHovedokosystem": return [setSelectedFilterHovedokosystem, selectedFilterHovedokosystem];
        case "ArealnumberFra": return [setArealnumberFra, ArealnumberFra];
        case "ArealnumberTil": return [setArealnumberTil, ArealnumberTil];
        case "Merknadsfelt": return [setMerknadsfelt, Merknadsfelt];
        case "selectedFilterNaturtyper": return [setSelectedFilterNaturtyper, selectedFilterNaturtyper];
        case "UK_Truetstate": return [setUK_Truetstate, UK_Truetstate];
        case "UK_NærTruetstate": return [setUK_NærTruetstate, UK_NærTruetstate];
        case "UK_SpesieltDårligKartlagtstate": return [setUK_SpesieltDårligKartlagtstate, UK_SpesieltDårligKartlagtstate];
        case "UK_SentralØkosystemFunksjonstate": return [setUK_SentralØkosystemFunksjonstate, UK_SentralØkosystemFunksjonstate];
        case "UK_SeBekrivelseNaturtypestate": return [setUK_SeBekrivelseNaturtypestate, UK_SeBekrivelseNaturtypestate];
        case "UK_IngenStatusstate": return [setUK_IngenStatusstate, UK_IngenStatusstate];
        default: return;
      }
    }
    const runFunction = (name, arg) => {
      var [thefunc, currentvar] = findfunc(name)
      if (Array.isArray(currentvar)) {
        var currentvar2 = currentvar.filter(item => item.value !== arg)
        thefunc(currentvar2)
      }
      else if (name === "ArealnumberFra" || name === "ArealnumberTil" || name === "Merknadsfelt") {
        thefunc(null)
        if (document.getElementById(name.toLowerCase() + "id") && document.getElementById(name.toLowerCase() + "id").value) {
          document.getElementById(name.toLowerCase() + "id").value = null;
        }
      }
      else {
        thefunc("Ingen filter")
      }
    }

    useEffect(
      () => {
        console.log("Filtereffect triggered")
        setFilterIsLoading(true)
        if (props.filterurl === "") { //Ikke tøm hvis denne ble trigget av filterurl
          emptyentirefilter() //Tøm filteret hvis noe ligger der fra før
        }
        var mainurl = ""

        loadModules(
            [
              "esri/rest/support/Query", "esri/rest/query" //query unngår på magisk vis CORS-problematikk
            ], { css: true })
          .then((
            [
              Query,
              query         
            ]) => {
          if(filtersetting === "Naturtyper") {
            console.log("Trigger Naturtyperpopulering")
            mainurl = naturtyperurl;
          } 
          else if (filtersetting === "Vern"){
            console.log("Trigger Vernpopulering")
            mainurl = vernurl;
          }
          // else{
          //   console.error(filtersetting)
          // }

          //DYNAMISK FLYKEHENTING (Fungerer kun for Vern)
          if(filtersetting === "Vern") {
            //Fylke (Det finnes objekter med flere fylker, f.eks "Viken,Oslo" eller "Nordland,Troms og Finnmark")
            //Fylke (Feltet finnes bare i Vern)
            var queryf = new Query();
            queryf.returnGeometry = false;
            queryf.outFields = "Fylker";
            queryf.where = "1=1";
            queryf.returnDistinctValues = true;
            query.executeQueryJSON(mainurl,queryf).then(result => {
              var temparr = []
              result.features.forEach(element => {
                if(element.attributes.Fylker.includes(",")){ // Del opp fylkene
                  var templist = element.attributes.Fylker.split(",")
                  templist.forEach(element2 => {
                    if (!(temparr.some(e => e.value === element2))){ //Vi liker ikke duplikater
                      temparr.push({ label: element2, value: element2 })
                    }
                  });
                }
                else {
                  if (!(temparr.some(e => e.value === element.attributes.Fylker))){ //Vi liker ikke duplikater
                    temparr.push({ label: element.attributes.Fylker, value: element.attributes.Fylker })
                  }
                }
              });
              setFylkeListArr(temparr);
            })
            .catch(ex => {
                console.log(ex);
            });
          }
          else if (filtersetting === "Naturtyper") {
            setFylkeListArr([ 
              { label: "Oslo (03)", value: "03"},
              { label: "Rogaland (11)", value: "11" },
              { label: "Møre og Romsdal (15)", value: "15" }, 
              { label: "Nordland (18)", value: "18" },
              { label: "Østfold (31)", value: "31" },
              { label: "Akershus (32)", value: "32" },
              { label: "Buskerud (33)", value: "33" },
              { label: "Innlandet (34)", value: "34" },
              { label: "Vestfold (39)", value: "39" },
              { label: "Telemark (40)", value: "40" },
              { label: "Agder (42)", value: "42"},
              { label: "Vestland (46)", value: "46" },
              { label: "Trøndelag (50)", value: "50" },
              { label: "Troms (55)", value: "55" },
              { label: "Finnmark (56)", value: "56" }
            ]); //Ikke lett å gjøre dynamisk siden fylker ikke finnes i Naturtyper datasettet
          }

          if(filtersetting !== null) {
            var queryko = new Query();
            queryko.returnGeometry = false;
            queryko.outFields = "Kommuner";
            queryko.where = "1=1";
            queryko.returnDistinctValues = true;
            query.executeQueryJSON(mainurl,queryko).then(result => {
              var temparr = []
              result.features.forEach(element => {
                //temparr.push({ label: element.attributes.Kommuner, value: element.attributes.Kommuner })
                if(element.attributes.Kommuner.includes(",")){ // Del opp kommunene
                    var templist = element.attributes.Kommuner.split(",");
                    templist.forEach(element2 => {
                      if (!(temparr.some(e => e.value === element2))){ //Vi liker ikke duplikater
                        temparr.push({ label: element2, value: element2 })
                      }
                    });
                  }
                  else {
                    if (!(temparr.some(e => e.value === element.attributes.Kommuner))){ //Vi liker ikke duplikater
                      temparr.push({ label: element.attributes.Kommuner, value: element.attributes.Kommuner })
                    }
                  }
              });
              setKommuneListArr(temparr);
            })
            .catch(ex => {
                console.log(ex);
            });
          }

          if(filtersetting === "Vern") {
            //Naturvernområde (kun Vern naturområder NiN) 
            var querynvo = new Query();
            querynvo.returnGeometry = false;
            querynvo.outFields = "NaturvernOmrådenavn";
            querynvo.where = "1=1";
            querynvo.returnDistinctValues = true;
            query.executeQueryJSON(mainurl,querynvo).then(result => {
              var temparr = []
              result.features.forEach(element => {
                if(element.attributes.NaturvernOmrådenavn && element.attributes.NaturvernOmrådenavn.includes(",")){ // Del opp Naturvernområdene (kan også være null)
                  var templist = element.attributes.NaturvernOmrådenavn.split(",");
                  templist.forEach(element2 => {
                    if (!(temparr.some(e => e.value === element2))){ //Vi liker ikke duplikater
                      temparr.push({ label: element2, value: element2 })
                    }
                  });
                }
                else if (element.attributes.NaturvernOmrådenavn) { //Vi liker ikke null som verdi
                  if (!(temparr.some(e => e.value === element.attributes.NaturvernOmrådenavn))){ //Vi liker ikke duplikater
                    temparr.push({ label: element.attributes.NaturvernOmrådenavn, value: element.attributes.NaturvernOmrådenavn })
                  }
                }
              });
              setNaturvernomradeListArr(temparr);
            })
            .catch(ex => {
                console.log(ex);
            });
          }

          if(filtersetting === "Naturtyper") {
            var queryProsjektomrade = new Query();
            queryProsjektomrade.returnGeometry = false;
            queryProsjektomrade.outFields = "Prosjektområdenavn,prosjektid";
            queryProsjektomrade.where = "1=1";
            queryProsjektomrade.returnDistinctValues = true;
            query.executeQueryJSON(naturtyperurlprosjektomrade,queryProsjektomrade).then(result => {
              var temparr = []
              result.features.forEach(element => {
                temparr.push({ label: element.attributes.Prosjektområdenavn, value: element.attributes.prosjektid })
              });
              setProsjektomradeListArr(temparr);
            })
            .catch(ex => {
                console.log(ex);
            });
          }
          //Kartleggingsenhetkode Hovedtype gruppe 
          var querykartleggingsenheterhg = new Query();
          querykartleggingsenheterhg.returnGeometry = false;
          querykartleggingsenheterhg.outFields = "hovedtypegruppe,hovedtypegruppebeskrivelse";
          querykartleggingsenheterhg.where = "1=1";
          querykartleggingsenheterhg.returnDistinctValues = true;
          query.executeQueryJSON(kartleggingsenheterurl,querykartleggingsenheterhg).then(result => {
            var temparr = []
            result.features.forEach(element => {
              //Manuelt ta ut uønskede
              if(filtersetting === "Naturtyper") {
                if (element.attributes.hovedtypegruppe !== "F" && element.attributes.hovedtypegruppe !== "H" && element.attributes.hovedtypegruppe !== "M" && element.attributes.hovedtypegruppebeskrivelse !== "Fastmarksskogsmark") {
                  temparr.push({ label: element.attributes.hovedtypegruppe + " (" + element.attributes.hovedtypegruppebeskrivelse + ")", value: element.attributes.hovedtypegruppe })
                }
              }
              else if(filtersetting === "Vern") {
                if (element.attributes.hovedtypegruppe !== "F" && element.attributes.hovedtypegruppe !== "H" && element.attributes.hovedtypegruppebeskrivelse !== "Fastmarksskogsmark") {
                  temparr.push({ label: element.attributes.hovedtypegruppe + " (" + element.attributes.hovedtypegruppebeskrivelse + ")", value: element.attributes.hovedtypegruppe })
                }
              }
            });
            setKartleggingsenheterhovedtypegruppeListArr(temparr);
          })
          .catch(ex => {
              console.log(ex);
          });

          //Kartleggingsenhetkode (fritekst)
          var querykartleggingsenheter = new Query();
          querykartleggingsenheter.returnGeometry = false;
          querykartleggingsenheter.outFields = "kartleggingsenhetkode,hovedtypegruppe,hovedtypegruppebeskrivelse,hovedtypebeskrivelse,kartleggingsenhetbeskrivelse";
          querykartleggingsenheter.where = "1=1";
          querykartleggingsenheter.returnDistinctValues = true;
          query.executeQueryJSON(kartleggingsenheterurl,querykartleggingsenheter).then(result => {
            var temparr = []
            result.features.forEach(element => {
              //Manuelt ta ut uønskede
              if(filtersetting === "Naturtyper") {
                if (element.attributes.hovedtypegruppe !== "F" && element.attributes.hovedtypegruppe !== "H" && element.attributes.hovedtypegruppe !== "M" && element.attributes.hovedtypegruppebeskrivelse !== "Fastmarksskogsmark") {
                  temparr.push({ label: element.attributes.kartleggingsenhetkode + " (" + element.attributes.hovedtypegruppebeskrivelse + ", " + element.attributes.hovedtypebeskrivelse + ", " + element.attributes.kartleggingsenhetbeskrivelse + ")", value: element.attributes.kartleggingsenhetkode })
                }
              }
              else if(filtersetting === "Vern") {
                if (element.attributes.hovedtypegruppe !== "F" && element.attributes.hovedtypegruppe !== "H" && element.attributes.hovedtypegruppebeskrivelse !== "Fastmarksskogsmark") {
                  temparr.push({ label: element.attributes.kartleggingsenhetkode + " (" + element.attributes.hovedtypegruppebeskrivelse + ", " + element.attributes.hovedtypebeskrivelse + ", " + element.attributes.kartleggingsenhetbeskrivelse + ")", value: element.attributes.kartleggingsenhetkode })
                }
              }
            });
            setKartleggingsenheterListArr(temparr);
          })
          .catch(ex => {
              console.log(ex);
          });

          //Variabler Gruppe 
          var queryvarg = new Query();
          queryvarg.returnGeometry = false;
          queryvarg.outFields = "gruppe,gruppebeskrivelse";
          queryvarg.where = "1=1";
          queryvarg.returnDistinctValues = true;
          queryvarg.orderByFields = "gruppe";
          query.executeQueryJSON(variablerurl,queryvarg).then(result => {
            var temparr = []
            result.features.forEach(element => {
              temparr.push({ label: element.attributes.gruppe + " (" + element.attributes.gruppebeskrivelse + ")", value: element.attributes.gruppe })
            });
            setVariablergruppeListArr(temparr);
          })
          .catch(ex => {
              console.log(ex);
          });

          //Variabler (fritekst)
          var queryvariabler = new Query();
          queryvariabler.returnGeometry = false;
          if (filtersetting === "Vern") {
            queryvariabler.where = "variabelkode IN (" + variabler.toString() + ")";
          }
          else {
            queryvariabler.where = "1=1";
          }
          queryvariabler.outFields = "variabelkode,gruppebeskrivelse,temabeskrivelse,navnbeskrivelse,trinnbeskrivelse";
          console.log(variabler.toString())

          query.executeForCount(variablerurl,queryvariabler).then(result => {
            var iteration = 0;
            var maxIteration = Math.ceil(result/1000);
            var temparr = []
            var queryvariabler = new Query();
            queryvariabler.returnGeometry = false;
            queryvariabler.outFields = "variabelkode,gruppebeskrivelse,temabeskrivelse,navnbeskrivelse,trinnbeskrivelse";
            if (filtersetting === "Naturtyper") {
              queryvariabler.where = "1=1";
            }
            else if (filtersetting === "Vern") {
              queryvariabler.where = "variabelkode IN (" + variabler.toString() + ")";
            }
            queryvariabler.returnDistinctValues = true;
            queryvariabler.orderByFields = "temabeskrivelse";
            // queryvariabler.where = "1=1";
            //queryvariabler.where = "variabelkode IN (" + variabler.toString() + ")";
            fetch(variablerurl + "?f=json")
              .then(res => res.json())
              .then(out => {
                getVariablerIteration(iteration, out.maxRecordCount, maxIteration, queryvariabler, query, variablerurl, temparr)  
              });
          });

          //Variabler (Navn) (kun vern ved oppstart)
          if (filtersetting === "Vern") {
            var queryvariablernavn = new Query();
            queryvariablernavn.returnGeometry = false;
            queryvariablernavn.outFields = "navnbeskrivelse,navn,temabeskrivelse,tema,gruppe";
            queryvariablernavn.where = "variabelkode IN (" + variabler.toString() + ")";
            queryvariablernavn.returnDistinctValues = true;
            queryvariablernavn.orderByFields = "navnbeskrivelse";
            query.executeQueryJSON(variablerurl,queryvariablernavn).then(result => {
              var temparr = []
              result.features.forEach(element => {
                var valuename = "";
                if (element.attributes.navn !== null){
                  valuename = "-" + element.attributes.navn
                }
                var myvalue = element.attributes.gruppe + element.attributes.tema + valuename
                var mylabel = element.attributes.navnbeskrivelse ?? element.attributes.temabeskrivelse
                temparr.push({ label: mylabel, value: myvalue })
              });
              // setVariablernavnListArr(temparr);
              setVariablernavnListArrVern(temparr);
            })
            .catch(ex => {
                console.log(ex);
            });

          //ULKM (kun vern)
          if (filtersetting === "Vern") {
            //ULKM (Hovedtype)
            var queryulkmhg = new Query();
            queryulkmhg.returnGeometry = false;
            queryulkmhg.outFields = "ninhovedtypegruppe";
            queryulkmhg.where = "1=1";
            queryulkmhg.returnDistinctValues = true;
            query.executeQueryJSON(ulkmurl,queryulkmhg).then(result => {
              var temparr = []
              result.features.forEach(element => {
                temparr.push({ label: element.attributes.ninhovedtypegruppe, value: element.attributes.ninhovedtypegruppe })
              });
              setUlkmNiNHovedtypegruppeListArr(temparr);
            })
            .catch(ex => {
                console.log(ex);
            });
          }

            //ULKM (fritekst)
            var queryulkm = new Query();
            queryulkm.returnGeometry = false;
            queryulkm.outFields = "ulkmkode,gradientkodebeskrivelse,trinnbeskrivelse";
            queryulkm.where = "1=1";
            queryulkm.returnDistinctValues = true;
            query.executeQueryJSON(ulkmurl,queryulkm).then(result => {
              var temparr = []
              result.features.forEach(element => {
                temparr.push({ label: element.attributes.ulkmkode + " (" + element.attributes.gradientkodebeskrivelse + ", " + element.attributes.trinnbeskrivelse + ")", value: element.attributes.ulkmkode })
              });
              setUlkmListArr(temparr); //Kartlaget har 321 enheter
            })
            .catch(ex => {
                console.log(ex);
            });
          }

          if(filtersetting === "Naturtyper") {
            //Kartleggingsår ("Kartlagtdato" i basen i "vern", "Kartleggingsår" i naturtyper) (Kun Naturtyper)
            var queryaar = new Query();
            queryaar.returnGeometry = false;
            queryaar.outFields = "Kartleggingsår";
            queryaar.where = "1=1";
            queryaar.returnDistinctValues = true;
            query.executeQueryJSON(mainurl,queryaar).then(result => {
              var temparr = []
              result.features.forEach(element => {
                temparr.push({ label: element.attributes.Kartleggingsår, value: element.attributes.Kartleggingsår })
              });
              setKartleggingsaarListArr(temparr);
            })
            .catch(ex => {
                console.log(ex);
            });
          }

          if(filtersetting === "Naturtyper") {
            //Oppdragsgiver (kun Naturtyper – Miljødirektoratets instruks)
            var queryoppdragsgiver = new Query();
            queryoppdragsgiver.returnGeometry = false;
            queryoppdragsgiver.outFields = "Oppdragsgiver";
            queryoppdragsgiver.where = "1=1";
            queryoppdragsgiver.returnDistinctValues = true;
            query.executeQueryJSON(mainurl,queryoppdragsgiver).then(result => {
              var temparr = []
              result.features.forEach(element => {
                if (element.attributes.Oppdragsgiver){ //Ta ut nullverdi
                  temparr.push({ label: element.attributes.Oppdragsgiver, value: element.attributes.Oppdragsgiver })
                }
              });
              setOppdragsgiverListArr(temparr);
            })
            .catch(ex => {
                console.log(ex);
            });
          }

          if(filtersetting !== null) {
            //Oppdragstaker i både vern og naturtyper)
            var queryoppdragstaker = new Query();
            queryoppdragstaker.returnGeometry = false;
            queryoppdragstaker.outFields = "Oppdragstaker";
            queryoppdragstaker.where = "1=1";
            queryoppdragstaker.returnDistinctValues = true;
            query.executeQueryJSON(mainurl,queryoppdragstaker).then(result => {
              var temparr = []
              result.features.forEach(element => {
                if (element.attributes.Oppdragstaker){ //Ta ut nullverdi
                  temparr.push({ label: element.attributes.Oppdragstaker, value: element.attributes.Oppdragstaker })
                }
              });
              setOppdragstakerListArr(temparr);
            })
            .catch(ex => {
                console.log(ex);
            });
          }

          if(filtersetting === "Naturtyper") {
            //Naturtyper (kun Naturtyper – Miljødirektoratets instruks)
            var querynt = new Query();
            querynt.returnGeometry = false;
            querynt.outFields = "Naturtypekode,Naturtype";
            querynt.orderByFields="Naturtype"
            querynt.where = "1=1";
            querynt.returnDistinctValues = true;
            query.executeQueryJSON(mainurl,querynt).then(result => {
              var temparr = []
              result.features.forEach(element => {
                temparr.push({ label: element.attributes.Naturtype + " (" + element.attributes.Naturtypekode + ")", value: element.attributes.Naturtypekode })
              });
              setNaturtyperListArr(temparr);
            })
            .catch(ex => {
                console.log(ex);
            });
          }

          if(filtersetting === "Naturtyper") {
            //Lokalitetskvalitet (kun naturtyper)
            setLokalitetskvalitetListArr([
              { label: "Svært høy kvalitet (0)", value: 0 }, 
              { label: "Høy kvalitet (1)", value: 1 },
              { label: "Moderat kvalitet (2)", value: 2 }, 
              { label: "Lav kvalitet (3)", value: 3 }, 
              { label: "Svært lav kvalitet (4)", value: 4 }, 
              { label: "Ikke kvalitetsvurdert (9)", value: 9 }
              ]); //TODO: Muligens hent dynamisk?

            //Tilstand (kun naturtyper)
            setTilstandListArr([
              { label: "God (0)", value: 0 }, 
              { label: "Moderat (1)", value: 1 },
              { label: "Dårlig (2)", value: 2 }, 
              { label: "Svært redusert (3)", value: 3 }
              ]); //TODO: Muligens hent dynamisk?

            //Naturmangfold (kun naturtyper)
            setNaturmangfoldListArr([
              { label: "Stort (0)", value: 0 }, 
              { label: "Moderat (1)", value: 1 },
              { label: "Lite (2)", value: 2 }
              ]); //TODO: Muligens hent dynamisk?

            //Hovedøkosystem (kun naturtyper)
            setHovedokosystemListArr([
              { label: "Naturlig åpne områder i lavlandet", value: "Naturlig åpne områder i lavlandet" },
              { label: "Naturlig åpne områder under skoggrensa", value: "Naturlig åpne områder under skoggrensa" },
              { label: "Fjell", value: "Fjell" }, 
              { label: "Skog", value: "Skog" },
              { label: "Semi-naturlig mark", value: "Semi-naturlig mark" },
              { label: "Våtmark", value: "Våtmark" },
              { label: "Ingen", value: "Ingen" }
              ]); //TODO: Muligens hent dynamisk?
          }

        });

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [filtersetting]);

      const getVariablerIteration = (iteration, maxRecordCount, maxIteration, queryvariabler, query, variablerurl, temparr) => {
        if (iteration >= maxIteration) { //Vi er ferdige
          setVariablerListArr(temparr); //kartlaget har ca 4500 enheter totalt       
          setFilterIsLoading(false) //TODO: Buuuurde alltid være den treigeste, men selvsagt ikke ideelt
        }
        else {
          queryvariabler.start = iteration * maxRecordCount; //resultOffset
          queryvariabler.num = maxRecordCount; //ResultRecordCount (1000 i variabler)
          query.executeQueryJSON(variablerurl,queryvariabler).then(result => {
            result.features.forEach(element => {
              temparr.push({ label: element.attributes.variabelkode + " (" + element.attributes.gruppebeskrivelse + ", " + element.attributes.temabeskrivelse + ", " + element.attributes.navnbeskrivelse  + ", " + element.attributes.trinnbeskrivelse + ")", value: element.attributes.variabelkode })
            });
            iteration += 1;
            getVariablerIteration(iteration, maxRecordCount, maxIteration, queryvariabler, query, variablerurl, temparr)
          })
          .catch(ex => {
              console.log(ex);
          });
        }
      };

      //For async søk (Variabler)
      const filterColors = (inputValue) => {
        if (variablerListArr && variablerListArr.length > 0) {
          return variablerListArr.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
          );
        }
        else {
          return
        }

      };
      //For async søk (Variabler)
      const loadOptions = (
        inputValue,
        callback,
      ) => {
        setTimeout(() => {
          callback(filterColors(inputValue));
        }, 1000);
      };
 
    return (
      <div id="outerContainer">
        {filterIsLoading ? (
          <div className="d-flex justify-content-center spinnerWrap">
            <div className="spinnerMiddle">
              <p><b>Laster filtere...</b></p>
              <PulseLoader                
                  size={50}
                  color={"#005e5d"}
                  loading={filterIsLoading}
              />
            </div>
          </div>
        ) 
        : "" }
        <div id="filterContainer" className="mb-0 border-bottom">
          <Header />
          <div className="filterContainerInner">
            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
              {/* React Tabs krever at like mange 'Tab' som 'TabPanel' eksisterer */}
              <TabList style={{display: "none"}}>
                <Tab>0.Oversikt</Tab>
                <Tab>1.Fylke</Tab>
                <Tab>2.Kommune</Tab>
                <Tab>3.Naturvernområde (kun Vern naturområder NiN)</Tab>
                <Tab>4.Utvalgskriterium (kun Naturtyper – Miljødirektoratets instruks)</Tab>
                <Tab>5.Naturtype (kun Naturtyper – Miljødirektoratets instruks)</Tab>
                <Tab>6.Lokalitetskvalitet (kun Naturtyper – Miljødirektoratets instruks)</Tab>
                <Tab>7.Tilstand (kun Naturtyper – Miljødirektoratets instruks)</Tab>
                <Tab>8.Naturmangfold (kun Naturtyper – Miljødirektoratets instruks)</Tab>
                <Tab>9.Kartleggingsenheter</Tab>
                <Tab>10.Variabler</Tab>
                <Tab>11.ULKM (kun Vern naturområder NiN)</Tab>
                <Tab>12.Hovedøkosystem (kun Naturtyper – Miljødirektoratets instruks)</Tab>
                <Tab>13.Kartleggingsår (kun Naturtyper – Miljødirektoratets instruks)</Tab>
                <Tab>14.Oppdragsgiver (kun Naturtyper – Miljødirektoratets instruks)</Tab>
                <Tab>15.Oppdragstaker</Tab>
                <Tab>16.Prosjektområde/dekningskart (kun Naturtyper – Miljødirektoratets instruks)</Tab>
              </TabList>
              <TabPanel className="maintabpanel">
                <div className="toprowcontainer">
                  <p className="bigtext m-0">Datasett</p>
                </div>
                <Select styles={customStylesfilterSetting} components={{IndicatorSeparator: () => null,}} options={[{ label: "Velg", value: null}, { label: "Naturtyper - Miljødirektoratets instruks", value: "Naturtyper"}, { label: "Natur i Verneområder (NiN)", value: "Vern"}]} onChange={(e) => statefilter(setFiltersettingOption, e)} placeholder="Velg" value={filtersettingOption}></Select>
                {filtersetting !== null ?
                <FilterSelect tabselectname={"Fylke"} setTabIndex={setTabIndex} tabIndex={1} setSelectedFilter={setSelectedFilterFylke} selectedFilter={selectedFilterFylke} />
                : "" }
                {filtersetting !== null ?
                <FilterSelect tabselectname={"Kommune"} setTabIndex={setTabIndex} tabIndex={2} setSelectedFilter={setSelectedFilterKommune} selectedFilter={selectedFilterKommune} />
                : "" }
                {filtersetting === "Vern" ?
                <FilterSelect tabselectname={"Naturvernområde"} setTabIndex={setTabIndex} tabIndex={3} setSelectedFilter={setSelectedFilterNaturvernomrade} selectedFilter={selectedFilterNaturvernomrade} /> 
                : "" }
                {filtersetting === "Naturtyper" ?
                <div className="col-12 mt-2 column">
                  <button className="btn btn-sm p-1 btn-forward w-100" onClick={() => setTabIndex(4)}>
                    Utvalgskriterium
                      <Select 
                        styles={customStylesouter} 
                        className="w-100"
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null,
                          MenuList: () => null,
                          Menu: () => null,
                          MultiValueRemove: () => <div className="custommultivalueremove" onClick={(e) => {e.stopPropagation();setUK_Truetstate("Ingen filter");setUK_NærTruetstate("Ingen filter");setUK_SpesieltDårligKartlagtstate("Ingen filter");setUK_SentralØkosystemFunksjonstate("Ingen filter");setUK_SeBekrivelseNaturtypestate("Ingen filter");setUK_IngenStatusstate("Ingen filter")}}><components.MultiValueRemove></components.MultiValueRemove></div>
                        }}
                        onChange={(e) => console.log(e)}
                        isMulti
                        isClearable={false}
                        isSearchable={false}
                        placeholder=""
                        value={UK_Counter > 0 ? [{ label: "(" + UK_Counter + ")", value: null}] : []}
                        >  
                      </Select>
                      <p className="icon-sm icon-sm-p" >
                        <span className="icon-sm icon-forward"/>
                      </p>
                  </button>
                </div>
                : "" }
                {filtersetting === "Naturtyper" ?
                <FilterSelect tabselectname={"Naturtype"} setTabIndex={setTabIndex} tabIndex={5} setSelectedFilter={setSelectedFilterNaturtyper} selectedFilter={selectedFilterNaturtyper} /> 
                : "" }
                {filtersetting === "Naturtyper" ?
                <FilterSelect tabselectname={"Lokalitetskvalitet"} setTabIndex={setTabIndex} tabIndex={6} setSelectedFilter={setSelectedFilterLokalitetskvalitet} selectedFilter={selectedFilterLokalitetskvalitet} /> 
                : "" }
                {filtersetting === "Vern" ? //Kun i Vern er Kartleggingsenheter utenfor Avansert 
                <FilterSelect tabselectname={"Kartleggingsenheter"} setTabIndex={setTabIndex} tabIndex={9} setSelectedFilter={setSelectedFilterKartleggingsenheter} selectedFilter={selectedFilterKartleggingsenheter} /> 
                : "" }
                {filtersetting === "Vern" ? //Kun i Vern er Variabler utenfor Avansert 
                <FilterSelect selectstyle={"beigebackground"} tabselectname={"Variabler"} setTabIndex={setTabIndex} tabIndex={10} setSelectedFilter={setSelectedFilterVariabler} selectedFilter={selectedFilterVariabler} /> 
                : "" }
                {filtersetting === "Vern" ?
                <FilterSelect selectstyle={"beigebackground"} tabselectname={"ULKM variabler"} setTabIndex={setTabIndex} tabIndex={11} setSelectedFilter={setSelectedFilterUlkm} selectedFilter={selectedFilterUlkm} /> 
                : "" }
                {filtersetting !== null ? 
                <Collapsible horizontal transitionTime={400} easing={"ease-out"} triggerWhenOpen={<div className="collapsetrigger"><p className="fontweightnormal mediumtextblack p-2 m-0"><b>Avansert</b></p><img className="m-2" src={collapseminus} alt="collapseminus" height="20"/></div>} trigger={<div className="collapsetrigger"><p className="mediumtextblack p-2 m-0"><b>Avansert</b></p><img className="m-2" src={collapseplus} alt="collapseplus" height="20"/></div>}>
                  {filtersetting === "Naturtyper" ? 
                  <div className="col-12 mt-2 d-flex justify-content-flex-center">
                    <p className="mediumtext p-2 m-0">Naturtypedata</p><hr className="w-100 ml-2 mr-2"/>
                  </div>
                  : "" }
                  {filtersetting === "Naturtyper" ? 
                  <FilterSelect selectstyle={"beigebackground"} tabselectname={"Tilstand"} setTabIndex={setTabIndex} tabIndex={7} setSelectedFilter={setSelectedFilterTilstand} selectedFilter={selectedFilterTilstand} /> 
                  : "" }
                  {filtersetting === "Naturtyper" ? 
                  <div className="col-12 mt-2 column">
                    <button className="btn btn-sm p-1 btn-forward w-100" onClick={() => setTabIndex(8)}>
                        Naturmangfold
                        <Select 
                          styles={customStylesouter} 
                          className="w-100"
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                            MenuList: () => null,
                            Menu: () => null,
                            MultiValueRemove: () => <div className="custommultivalueremove" onClick={(e) => {e.stopPropagation();setSelectedFilterNaturmangfold(null)}}><components.MultiValueRemove></components.MultiValueRemove></div>
                          }}
                          onChange={(e) => console.log(e)}
                          isMulti
                          isClearable={false}
                          isSearchable={false}
                          placeholder=""
                          value={selectedFilterNaturmangfold && selectedFilterNaturmangfold.length > 0 ? [{ label: "(" + selectedFilterNaturmangfold.length + ")", value: null}] : []}>  
                        </Select>
                        <p className="icon-sm icon-sm-p" >
                          <span className="icon-sm icon-forward"/>
                        </p>
                    </button>
                  </div>
                  : "" }
                  {filtersetting === "Naturtyper" ? //Kun i naturtyper er Kartleggingsenheter i Avansert 
                  <div className="col-12 mt-2 column">
                    <button className="btn btn-sm p-1 btn-forward w-100 beigebackground" onClick={() => setTabIndex(9)}>
                        Kartleggingsenheter
                        <Select 
                          styles={customStylesouter} 
                          className="w-100"
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                            MenuList: () => null,
                            Menu: () => null,
                            MultiValueRemove: () => <div className="custommultivalueremove" onClick={(e) => {e.stopPropagation();setSelectedFilterKartleggingsenheter(null)}}><components.MultiValueRemove></components.MultiValueRemove></div>
                          }}
                          onChange={(e) => console.log(e)}
                          isMulti
                          isClearable={false}
                          isSearchable={false}
                          placeholder=""
                          value={selectedFilterKartleggingsenheter && selectedFilterKartleggingsenheter.length > 0 ? [{ label: "(" + selectedFilterKartleggingsenheter.length + ")", value: null}] : []}>  
                        </Select>
                        <p className="icon-sm icon-sm-p" >
                          <span className="icon-sm icon-forward"/>
                        </p>
                    </button>
                  </div>
                  : "" }
                  {filtersetting === "Naturtyper" ? //Kun i naturtyper er Kartleggingsenheter i Avansert  
                  <div className="col-12 mt-2 column">
                    <button className="btn btn-sm p-1 btn-forward w-100 beigebackground" onClick={() => setTabIndex(10)}>
                        Variabler
                        <Select 
                          styles={customStylesouter} 
                          className="w-100"
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                            MenuList: () => null,
                            Menu: () => null,
                            MultiValueRemove: () => <div className="custommultivalueremove" onClick={(e) => {e.stopPropagation();setSelectedFilterVariabler(null)}}><components.MultiValueRemove></components.MultiValueRemove></div>
                          }}
                          onChange={(e) => console.log(e)}
                          isMulti
                          isClearable={false}
                          isSearchable={false}
                          placeholder=""
                          value={selectedFilterVariabler&& selectedFilterVariabler.length > 0 ? [{ label: "(" + selectedFilterVariabler.length + ")", value: null}] : []}>  
                        </Select>
                        <p className="icon-sm icon-sm-p" >
                          <span className="icon-sm icon-forward"/>
                        </p>
                    </button>
                  </div>
                  : "" }
                  {filtersetting === "Naturtyper" ? 
                  <div className="col-12 mt-2 column">
                    <button className="btn btn-sm p-1 btn-forward w-100" onClick={() => setTabIndex(12)}>
                        Hovedøkosystem
                        <Select 
                          styles={customStylesouter} 
                          className="w-100"
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                            MenuList: () => null,
                            Menu: () => null,
                            MultiValueRemove: () => <div className="custommultivalueremove" onClick={(e) => {e.stopPropagation();setSelectedFilterHovedokosystem(null)}}><components.MultiValueRemove></components.MultiValueRemove></div>
                          }}
                          onChange={(e) => console.log(e)}
                          isMulti
                          isClearable={false}
                          isSearchable={false}
                          placeholder=""
                          value={selectedFilterHovedokosystem && selectedFilterHovedokosystem.length > 0 ? [{ label: "(" + selectedFilterHovedokosystem.length + ")", value: null}] : []}>  
                        </Select>
                        <p className="icon-sm icon-sm-p" >
                          <span className="icon-sm icon-forward"/>
                        </p>
                    </button>
                  </div>
                  : "" }
                  {filtersetting !== null ? 
                  <div className="col-12 mt-2 column">
                    <p className="btn-sm p-2 m-auto">Areal (m<sup>2</sup>)</p>
                  </div>
                  : "" }
                  {filtersetting !== null ? 
                  <div className="col-12 column">
                    <div className="row arealrow pl-2">
                      <input id="arealnumberfraid" className="arealinput" type="number" defaultValue={ArealnumberFra} onBlur={(e) => statechangetext(setArealnumberFra,e)} placeholder="Fra" />
                      <div className="col-2">
                        <hr className="arealdivider"/>
                      </div>
                      <input id="arealnumbertilid" className="arealinput" type="number" defaultValue={ArealnumberTil} onBlur={(e) => statechangetext(setArealnumberTil,e)} placeholder="Til" />
                    </div>                
                  </div>
                  : "" }
                  {filtersetting !== null ? 
                  <div className="col-12 mt-2 column">
                    <p className="valgtfilter"><b>Valgt filter:</b> {ArealnumberFra ? ( "Areal >= " + ArealnumberFra + "m²") : ""} {ArealnumberFra && ArealnumberTil ? ( " og ") : ""} {ArealnumberTil ? ( "Areal <= " + ArealnumberTil + "m²") : ""}</p>
                    {/* <button onClick={(e) => statechangeresetareal(e, "reset")} className="btn btn-sm">(Tøm filter)</button> */}
                  </div>
                  : "" }
                  {filtersetting === "Naturtyper" ? 
                  <div className="col-12 mt-2 d-flex justify-content-flex-center">
                    <p className="mediumtext p-2 m-0">Prosjektdata</p><hr className="w-100 ml-2 mr-2"/>
                  </div>
                  : "" }
                  {filtersetting === "Naturtyper" ? 
                  <div className="col-12 mt-2 column">
                    <button className="btn btn-sm p-1 btn-forward w-100" onClick={() => setTabIndex(13)}>
                        Kartleggingsår
                        <Select 
                          styles={customStylesouter} 
                          className="w-100"
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                            MenuList: () => null,
                            Menu: () => null,
                            MultiValueRemove: () => <div className="custommultivalueremove" onClick={(e) => {e.stopPropagation();setSelectedFilterKartleggingsaar(null)}}><components.MultiValueRemove></components.MultiValueRemove></div>
                          }}
                          onChange={(e) => console.log(e)}
                          isMulti
                          isClearable={false}
                          isSearchable={false}
                          placeholder=""
                          value={selectedFilterKartleggingsaar && selectedFilterKartleggingsaar.length > 0 ? [{ label: "(" + selectedFilterKartleggingsaar.length + ")", value: null}] : []}>  
                        </Select>
                        <p className="icon-sm icon-sm-p" >
                          <span className="icon-sm icon-forward"/>
                        </p>
                    </button>
                  </div>
                  : "" }
                  {filtersetting === "Naturtyper" ? 
                  <div className="col-12 mt-2 column">
                    <button className="btn btn-sm p-1 btn-forward w-100" onClick={() => setTabIndex(14)}>
                        Oppdragsgiver
                        <Select 
                          styles={customStylesouter} 
                          className="w-100"
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                            MenuList: () => null,
                            Menu: () => null,
                            MultiValueRemove: () => <div className="custommultivalueremove" onClick={(e) => {e.stopPropagation();setSelectedFilterOppdragsgiver(null)}}><components.MultiValueRemove></components.MultiValueRemove></div>
                          }}
                          onChange={(e) => console.log(e)}
                          isMulti
                          isClearable={false}
                          isSearchable={false}
                          placeholder=""
                          value={selectedFilterOppdragsgiver && selectedFilterOppdragsgiver.length > 0 ? [{ label: "(" + selectedFilterOppdragsgiver.length + ")", value: null}] : []}>  
                        </Select>
                        <p className="icon-sm icon-sm-p" >
                          <span className="icon-sm icon-forward"/>
                        </p>
                    </button>
                  </div>
                  : "" }
                  {filtersetting !== null ? 
                  <div className="col-12 mt-2 column">
                    <button className="btn btn-sm p-1 btn-forward w-100" onClick={() => setTabIndex(15)}>
                        Oppdragstaker
                        <Select 
                          styles={customStylesouter} 
                          className="w-100"
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                            MenuList: () => null,
                            Menu: () => null,
                            MultiValueRemove: () => <div className="custommultivalueremove" onClick={(e) => {e.stopPropagation();setSelectedFilterOppdragstaker(null)}}><components.MultiValueRemove></components.MultiValueRemove></div>
                          }}
                          onChange={(e) => console.log(e)}
                          isMulti
                          isClearable={false}
                          isSearchable={false}
                          placeholder=""
                          value={selectedFilterOppdragstaker && selectedFilterOppdragstaker.length > 0 ? [{ label: "(" + selectedFilterOppdragstaker.length + ")", value: null}] : []}>  
                        </Select>
                        <p className="icon-sm icon-sm-p" >
                          <span className="icon-sm icon-forward"/>
                        </p>
                    </button>
                  </div>
                  : "" }
                  {filtersetting === "Naturtyper" ? 
                  <div className="col-12 mt-2 column">
                    <button className="btn btn-sm p-1 btn-forward w-100" onClick={() => setTabIndex(16)}>
                        Prosjektområde
                        <Select 
                          styles={customStylesouter} 
                          className="w-100"
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                            MenuList: () => null,
                            Menu: () => null,
                            MultiValueRemove: () => <div className="custommultivalueremove" onClick={(e) => {e.stopPropagation();setSelectedFilterProsjektomrade(null)}}><components.MultiValueRemove></components.MultiValueRemove></div>
                          }}
                          onChange={(e) => console.log(e)}
                          isMulti
                          isClearable={false}
                          isSearchable={false}
                          placeholder=""
                          value={selectedFilterProsjektomrade && selectedFilterProsjektomrade.length > 0 ? [{ label: "(" + selectedFilterProsjektomrade.length + ")", value: null}] : []}>  
                        </Select>
                        <p className="icon-sm icon-sm-p" >
                          <span className="icon-sm icon-forward"/>
                        </p>
                    </button>
                  </div>
                  : "" }
                  {filtersetting === "Vern" ?   
                  <div className="col-12 mt-2">
                    <p className="p-2 m-0 btn btn-sm">Merknadsfelt</p>  
                  </div>
                  : "" }
                  {filtersetting === "Vern" ? 
                  <div className="col-12 column">
                    <div className="row merknadsfeltrow pl-2">
                      <input id="merknadsfeltid" className="merknadsfeltinput" type="text" defaultValue={Merknadsfelt} onBlur={(e) => statechangetext(setMerknadsfelt,e)} placeholder="Tekstlig søk" />
                    </div>                
                  </div>
                  : "" }
                </Collapsible>
                : "" }
                {/* {filtersetting !== null ? 
                <div className="searchContainer mt-2">
                  {filtersetting === "Naturtyper" ? 
                  <button className="btn btn-primary btn-sm btn-block w-100" onClick={() => doRefreshnaturtyper(prev => prev + 1)}>Søk i 'Naturtyper - Miljødirektoratets instruks'</button>
                  : "" }
                  {filtersetting === "Vern" ? 
                  <button className="btn btn-primary btn-sm btn-block w-100" onClick={() => doRefreshvern(prev => prev + 1)}>Søk i 'Natur i Verneområder (NiN)'</button>
                  : "" }
                </div>
                : "" } */}
              </TabPanel>
              <TabPanel className="maintabpanel">
                <button className="btn btn-sm btn-back w-100" onClick={() => setTabIndex(0)}><img className="m-2" src={backarrow} alt="collapseminus" height="12"/>Tilbake</button>
                <div className="col-12 mt-3 d-flex justify-content-flex-center">
                  <p className="mediumtext p-2 m-0">Velg fylke</p><hr className="w-100 ml-2 mr-2"/>
                </div>
                {/* <div className="col-12 mt-3">
                  <Select styles={customStyles} options={fylkeListArr} isMulti isClearable="true" onChange={(e) => statechange(setSelectedFilterFylke, e)} placeholder="Søk i fylker" value={selectedFilterFylke}></Select>
                </div> */}
                <div className="col-12 p-2 smallmediumtextblack">
                  {fylkeListArr && fylkeListArr.length > 0 ? (<div>{fylkeListArr.map((object, i) => 
                  <div className="form-check" key={i} >
                    <input className="form-check-input" type="checkbox" name={object.label} value={object} defaultChecked={selectedFilterFylke && selectedFilterFylke.length > 0 && selectedFilterFylke.includes(object) ? true : false} id={object.label} 
                    onClick={(e) => statechangelist(setSelectedFilterFylke, selectedFilterFylke, e, object)}/>
                    <label className="form-check-label" htmlFor={object.label}>
                      <p>{object.label}</p>
                    </label>
                  </div>
                  )}</div>) : "" }
                </div>
              </TabPanel>
              <TabPanel className="maintabpanel">
                <button className="btn btn-sm btn-back w-100" onClick={() => setTabIndex(0)}><img className="m-2" src={backarrow} alt="collapseminus" height="12"/>Tilbake</button>
                <div className="col-12 mt-3 d-flex justify-content-flex-center">
                  <p className="mediumtext p-2 m-0">Velg kommune</p><hr className="w-100 ml-2 mr-2"/>
                </div>
                <div className="col-12 mt-2">
                  <Select styles={customStyles} options={kommuneListArr} isMulti isClearable="true" onChange={(e) => statechange(setSelectedFilterKommune, e)} placeholder="Søk i kommuner" value={selectedFilterKommune}></Select>
                </div>
                <div className="col-12 p-2 smallmediumtextblack">
                  {kommuneListArr && kommuneListArr.length > 0 ? (<div>{kommuneListArr.map((object, i) => 
                  <div className="form-check" key={i} >
                    <input className="form-check-input" type="checkbox" name={object.label} value={object} defaultChecked={selectedFilterKommune && selectedFilterKommune.length > 0 && selectedFilterKommune.includes(object) ? true : false} id={object.label} 
                    onClick={(e) => statechangelist(setSelectedFilterKommune, selectedFilterKommune, e, object)}/>
                    <label className="form-check-label" htmlFor={object.label}>
                      <p>{object.label}</p>
                    </label>
                  </div>
                  )}</div>) : "" }
                </div>
              </TabPanel>
              <TabPanel className="maintabpanel">
                <button className="btn btn-sm btn-back w-100" onClick={() => setTabIndex(0)}><img className="m-2" src={backarrow} alt="collapseminus" height="12"/>Tilbake</button>
                <div className="col-12 mt-3 d-flex justify-content-flex-center">
                  <p className="mediumtext p-2 m-0">Velg naturvernområde</p><hr className="w-100 ml-2 mr-2"/>
                </div>
                <div className="col-12 mt-2">
                  <Select styles={customStyles} options={naturvernomradeListArr} isMulti isClearable="true" onChange={(e) => statechange(setSelectedFilterNaturvernomrade, e)} placeholder="Søk i naturvernområder" value={selectedFilterNaturvernomrade}></Select>
                </div> 
              </TabPanel>
              <TabPanel className="maintabpanel">
                <button className="btn btn-sm btn-back w-100" onClick={() => setTabIndex(0)}><img className="m-2" src={backarrow} alt="collapseminus" height="12"/>Tilbake</button>
                <div className="col-12 mt-3 d-flex justify-content-flex-center">
                <p className="mediumtext p-2 m-0">Utvalgskriterium</p><hr className="w-100 ml-2 mr-2"/>
                </div>
                <div className="col-12 p-2 smallmediumtextblack">
                  {/* <p>Utvalgskriterier</p> */}
                  {/* <p className="m-0 lh-38px">Truet (UK_Truet)</p> */}
                  <div className="m-auto">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="radiouk_truet" checked={UK_Truetstate !== "Ingen filter"} id="radiouk_truetIngenfilter" value="Ingen filter" onChange={(e) => statechangetext(setUK_Truetstate,e)}/>
                      <label className="form-check-label">
                        Truet
                      </label>
                    </div>
                    <div className="janeicontainer">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="radiouk_truet" checked={UK_Truetstate === "Ja"} id="radiouk_truetJa" value="Ja" onChange={(e) => statechangetext(setUK_Truetstate,e)}/>
                        <label className="form-check-label">
                          Ja
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="radiouk_truet" checked={UK_Truetstate === "Nei"} id="radiouk_truetNei" value="Nei" onChange={(e) => statechangetext(setUK_Truetstate,e)}/>
                        <label className="form-check-label">
                          Nei
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <p className="m-0 lh-38px">Nær Truet (UK_NærTruet)</p> */}
                  <div className="m-auto">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="radiouk_naertruet" checked={UK_NærTruetstate !== "Ingen filter"} id="radiouk_naertruetIngenfilter" value="Ingen filter" onChange={(e) => statechangetext(setUK_NærTruetstate,e)}/>
                      <label className="form-check-label">
                        Nær Truet
                      </label>
                    </div>
                    <div className="janeicontainer">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="radiouk_naertruet" checked={UK_NærTruetstate === "Ja"} id="radiouk_naertruetJa" value="Ja" onChange={(e) => statechangetext(setUK_NærTruetstate,e)}/>
                        <label className="form-check-label">
                          Ja
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="radiouk_naertruet" checked={UK_NærTruetstate === "Nei"} id="radiouk_naertruetNei" value="Nei" onChange={(e) => statechangetext(setUK_NærTruetstate,e)}/>
                        <label className="form-check-label">
                          Nei
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <p className="m-0 lh-38px">Spesielt dårlig kartlagt (UK_SpesieltDårligKartlagt)</p> */}
                  <div className="m-auto">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="radiouk_spesieltdarligkartlagt" checked={UK_SpesieltDårligKartlagtstate !== "Ingen filter"} id="radiouk_spesieltdarligkartlagtIngenfilter" value="Ingen filter" onChange={(e) => statechangetext(setUK_SpesieltDårligKartlagtstate,e)}/>
                      <label className="form-check-label">
                        Spesielt dårlig kartlagt
                      </label>
                    </div>
                    <div className="janeicontainer">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="radiouk_spesieltdarligkartlagt" checked={UK_SpesieltDårligKartlagtstate === "Ja"} id="radiouk_spesieltdarligkartlagtJa" value="Ja" onChange={(e) => statechangetext(setUK_SpesieltDårligKartlagtstate,e)}/>
                        <label className="form-check-label">
                          Ja
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="radiouk_spesieltdarligkartlagt" checked={UK_SpesieltDårligKartlagtstate === "Nei"} id="radiouk_spesieltdarligkartlagtNei" value="Nei" onChange={(e) => statechangetext(setUK_SpesieltDårligKartlagtstate,e)}/>
                        <label className="form-check-label">
                          Nei
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <p className="m-0 lh-38px">Sentral økosystemfunksjon (UK_SentralØkosystemFunksjon)</p> */}
                  <div className="m-auto">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="radiouk_sentralokosystemfunksjon" checked={UK_SentralØkosystemFunksjonstate !== "Ingen filter"} id="radiouk_sentralokosystemfunksjonIngenfilter" value="Ingen filter" onChange={(e) => statechangetext(setUK_SentralØkosystemFunksjonstate,e)}/>
                      <label className="form-check-label">
                        Sentral økosystemfunksjon
                      </label>
                    </div>
                    <div className="janeicontainer">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="radiouk_sentralokosystemfunksjon" checked={UK_SentralØkosystemFunksjonstate === "Ja"} id="radiouk_sentralokosystemfunksjonJa" value="Ja" onChange={(e) => statechangetext(setUK_SentralØkosystemFunksjonstate,e)}/>
                        <label className="form-check-label">
                          Ja
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="radiouk_sentralokosystemfunksjon" checked={UK_SentralØkosystemFunksjonstate === "Nei"} id="radiouk_sentralokosystemfunksjonNei" value="Nei" onChange={(e) => statechangetext(setUK_SentralØkosystemFunksjonstate,e)}/>
                        <label className="form-check-label">
                          Nei
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <p className="m-0 lh-38px">Se beskrivelse av naturtypen (UK_SeBekrivelseNaturtype)</p> */}
                  <div className="m-auto">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="radiouk_sebekrivelsenaturtype" checked={UK_SeBekrivelseNaturtypestate !== "Ingen filter"} id="radiouk_sebekrivelsenaturtypeIngenfilter" value="Ingen filter" onChange={(e) => statechangetext(setUK_SeBekrivelseNaturtypestate,e)}/>
                      <label className="form-check-label">
                        Se beskrivelse av naturtypen
                      </label>
                    </div>
                    <div className="janeicontainer">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="radiouk_sebekrivelsenaturtype" checked={UK_SeBekrivelseNaturtypestate === "Ja"} id="radiouk_sebekrivelsenaturtypeJa" value="Ja" onChange={(e) => statechangetext(setUK_SeBekrivelseNaturtypestate,e)}/>
                        <label className="form-check-label">
                          Ja
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="radiouk_sebekrivelsenaturtype" checked={UK_SeBekrivelseNaturtypestate === "Nei"} id="radiouk_sebekrivelsenaturtypeNei" value="Nei" onChange={(e) => statechangetext(setUK_SeBekrivelseNaturtypestate,e)}/>
                        <label className="form-check-label">
                          Nei
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <p className="m-0 lh-38px">Ingen Status (UK_IngenStatus)</p> */}
                  <div className="m-auto">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="radiouk_ingenstatus" checked={UK_IngenStatusstate !== "Ingen filter"} id="radiouk_ingenstatusIngenfilter" value="Ingen filter" onChange={(e) => statechangetext(setUK_IngenStatusstate,e)}/>
                      <label className="form-check-label">
                        Ingen Status
                      </label>
                    </div>
                    <div className="janeicontainer">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="radiouk_ingenstatus" checked={UK_IngenStatusstate === "Ja"} id="radiouk_ingenstatusJa" value="Ja" onChange={(e) => statechangetext(setUK_IngenStatusstate,e)}/>
                        <label className="form-check-label">
                          Ja
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="radiouk_ingenstatus" checked={UK_IngenStatusstate === "Nei"} id="radiouk_ingenstatusNei" value="Nei" onChange={(e) => statechangetext(setUK_IngenStatusstate,e)}/>
                        <label className="form-check-label">
                          Nei
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel className="maintabpanel">
                <button className="btn btn-sm btn-back w-100" onClick={() => setTabIndex(0)}><img className="m-2" src={backarrow} alt="collapseminus" height="12"/>Tilbake</button>
                <div className="col-12 mt-3 d-flex justify-content-flex-center">
                  <p className="mediumtext p-2 m-0">Velg naturtype</p><hr className="w-100 ml-2 mr-2"/>
                </div>
                <div className="col-12 mt-2">
                  <Select styles={customStyles} options={naturtyperListArr} isMulti isClearable="true" onChange={(e) => statechange(setSelectedFilterNaturtyper, e)} placeholder="Søk i naturtyper" value={selectedFilterNaturtyper}></Select>
                </div>
              </TabPanel>
              <TabPanel className="maintabpanel">
                <button className="btn btn-sm btn-back w-100" onClick={() => setTabIndex(0)}><img className="m-2" src={backarrow} alt="collapseminus" height="12"/>Tilbake</button>
                <div className="col-12 mt-3 d-flex justify-content-flex-center">
                  <p className="mediumtext p-2 m-0">Velg lokalitetskvalitet</p><hr className="w-100 ml-2 mr-2"/>
                </div>
                {/* <div className="col-12 mt-2">
                  <Select styles={customStyles} options={lokalitetskvalitetListArr} isMulti isClearable="true" onChange={(e) => statechange(setSelectedFilterLokalitetskvalitet, e)} placeholder="Søk i lokalitetskvaliteter" value={selectedFilterLokalitetskvalitet}></Select>
                </div> */}
                <div className="col-12 p-2 smallmediumtextblack">
                  {lokalitetskvalitetListArr && lokalitetskvalitetListArr.length > 0 ? (<div>{lokalitetskvalitetListArr.map((object, i) => 
                  <div className="form-check" key={i} >
                    <input className="form-check-input" type="checkbox" name={object.label} value={object} defaultChecked={selectedFilterLokalitetskvalitet && selectedFilterLokalitetskvalitet.length > 0 && selectedFilterLokalitetskvalitet.includes(object) ? true : false} id={object.label} 
                    onClick={(e) => statechangelist(setSelectedFilterLokalitetskvalitet, selectedFilterLokalitetskvalitet, e, object)}/>
                    <label className="form-check-label" htmlFor={object.label}>
                      <p>{object.label}</p>
                    </label>
                  </div>
                  )}</div>) : "" }
                </div>
              </TabPanel>
              <TabPanel className="maintabpanel">
              <button className="btn btn-sm btn-back w-100" onClick={() => setTabIndex(0)}><img className="m-2" src={backarrow} alt="collapseminus" height="12"/>Tilbake</button>
              <div className="col-12 mt-3 d-flex justify-content-flex-center">
                <p className="mediumtext p-2 m-0">Velg tilstand</p><hr className="w-100 ml-2 mr-2"/>
              </div>
              {/* <div className="col-12 mt-2">
                <Select styles={customStyles} options={tilstandListArr} isMulti isClearable="true" onChange={(e) => statechange(setSelectedFilterTilstand, e)} placeholder="Søk i tilstander" value={selectedFilterTilstand}></Select>
              </div> */}
              <div className="col-12 p-2 smallmediumtextblack">
                {tilstandListArr && tilstandListArr.length > 0 ? (<div>{tilstandListArr.map((object, i) => 
                <div className="form-check" key={i} >
                  <input className="form-check-input" type="checkbox" name={object.label} value={object} defaultChecked={selectedFilterTilstand && selectedFilterTilstand.length > 0 && selectedFilterTilstand.includes(object) ? true : false} id={object.label} 
                  onClick={(e) => statechangelist(setSelectedFilterTilstand, selectedFilterTilstand, e, object)}/>
                  <label className="form-check-label" htmlFor={object.label}>
                    <p>{object.label}</p>
                  </label>
                </div>
                )}</div>) : "" }
              </div>
              </TabPanel>
              <TabPanel className="maintabpanel">
              <button className="btn btn-sm btn-back w-100" onClick={() => setTabIndex(0)}><img className="m-2" src={backarrow} alt="collapseminus" height="12"/>Tilbake</button>
              <div className="col-12 mt-3 d-flex justify-content-flex-center">
                <p className="mediumtext p-2 m-0">Velg naturmangfold</p><hr className="w-100 ml-2 mr-2"/>
              </div>
              {/* <div className="col-12 mt-2">
                <Select styles={customStyles} options={naturmangfoldListArr} isMulti isClearable="true" onChange={(e) => statechange(setSelectedFilterNaturmangfold, e)} placeholder="Søk i naturmangfolder" value={selectedFilterNaturmangfold}></Select>
              </div> */}
              <div className="col-12 p-2 smallmediumtextblack">
                {naturmangfoldListArr && naturmangfoldListArr.length > 0 ? (<div>{naturmangfoldListArr.map((object, i) => 
                <div className="form-check" key={i} >
                  <input className="form-check-input" type="checkbox" name={object.label} value={object} defaultChecked={selectedFilterNaturmangfold && selectedFilterNaturmangfold.length > 0 && selectedFilterNaturmangfold.includes(object) ? true : false} id={object.label} 
                  onClick={(e) => statechangelist(setSelectedFilterNaturmangfold, selectedFilterNaturmangfold, e, object)}/>
                  <label className="form-check-label" htmlFor={object.label}>
                    <p>{object.label}</p>
                  </label>
                </div>
                )}</div>) : "" }
              </div>
              </TabPanel>
              <TabPanel className="maintabpanel">
              <button className="btn btn-sm btn-back w-100" onClick={() => setTabIndex(0)}><img className="m-2" src={backarrow} alt="collapseminus" height="12"/>Tilbake</button>
              <div className="col-12 mt-3 d-flex justify-content-flex-center">
                <p className="mediumtext p-2 m-0">Kartleggingsenheter</p><hr className="w-100 ml-2 mr-2"/>
              </div>
              <div className="col-12 mt-2">
                <p>Fritekstsøk (du kan bruke flere ord)</p>
                <div className="text-left">
                  <Select styles={customStyles} ref={(ref) => { setselectRefk(ref); }} openMenuOnFocus={true} options={kartleggingsenheterListArr} isMulti isClearable="true" onChange={(e) => statechange(setSelectedFilterKartleggingsenheter, e)} placeholder="Kartleggingsenhet" value={selectedFilterKartleggingsenheter}></Select>
                </div>
              </div>
              <div className="col-12 mt-2 d-flex justify-content-flex-center">
                <p className="mediumtext p-2 m-0">Velg kartleggingsenheter</p><hr className="w-100 ml-2 mr-2"/>
              </div>
              <div className="col-12 p-1">
                <p className="m-0 lh-38px">Hovedtype gruppe</p>
                <div className="col-12 text-left">
                  <Select className="mb-2px" styles={customStyles} options={kartleggingsenheterhovedtypegruppeListArr} isClearable="true" onChange={(e) => statechange(setSelectedFilterKartleggingsenheterhovedtypegruppe, e)} placeholder="Velg hovedtype gruppe" value={selectedFilterKartleggingsenheterhovedtypegruppe}></Select>
                </div>
                <p className="m-0 lh-38px">Hovedtype</p>
                <div className="col-12 text-left">
                  <Select className="mb-2px" styles={customStyles} options={kartleggingsenheterhovedtypeListArr} isDisabled={kartleggingsenheterhovedtypedisabled} isClearable="true" onChange={(e) => statechange(setSelectedFilterKartleggingsenheterhovedtype, e)} placeholder="Velg hovedtype" value={selectedFilterKartleggingsenheterhovedtype}></Select>
                </div>
                <p className="m-0 lh-38px">Kartleggingsenhet</p>
                <div className="col-12 text-left">
                  <Select className="mb-2px" styles={customStyles} options={kartleggingsenheterkartleggingsenhetListArr} isDisabled={kartleggingsenheterkartleggingsenhetdisabled} isClearable="true" onChange={(e) => statechange(setSelectedFilterKartleggingsenheterkartleggingsenhet, e)} placeholder="Velg kartleggingsenhet" value={selectedFilterKartleggingsenheterkartleggingsenhet}></Select>
                </div>
                <div className="mt-2 kodecontainer">
                  <div className="col-2 text-left">
                    <p className="m-0"><b>Kode:</b></p>
                  </div>
                  <div className="col-10 text-right">
                    <p className="m-0">{selectedFilterKartleggingsenheterkode}</p>
                  </div>
                </div>
                <button className="btn btn-primary btn-sm btn-block filterbutton" onClick={(e) => addFilter(setSelectedFilterKartleggingsenheter, selectedFilterKartleggingsenheter, selectedFilterKartleggingsenheterkode, selectRefk)}>Legg til filter</button>
              </div>
              </TabPanel>
              <TabPanel className="maintabpanel">
              <button className="btn btn-sm btn-back w-100" onClick={() => setTabIndex(0)}><img className="m-2" src={backarrow} alt="collapseminus" height="12"/>Tilbake</button>
              <div className="col-12 mt-3 d-flex justify-content-flex-center">
                <p className="mediumtext p-2 m-0">Variabler</p><hr className="w-100 ml-2 mr-2"/>
              </div>
              <div className="col-12 mt-2">
                <p>Fritekstsøk (du kan bruke flere ord)</p>
                <div className="text-left">
                  <AsyncSelect styles={customStyles} ref={(ref) => { setselectRefv(ref); }} openMenuOnFocus={true} options={variablerListArr} isMulti isClearable="true" loadOptions={loadOptions} cacheOptions defaultOptions onChange={(e) => statechange(setSelectedFilterVariabler, e)} placeholder="Variabel" value={selectedFilterVariabler} ></AsyncSelect>
                </div>
              </div>
              <div className="col-12 mt-2 d-flex justify-content-flex-center">
                <p className="mediumtext p-2 m-0">Velg variabler</p><hr className="w-100 ml-2 mr-2"/>
              </div>
              <div className="col-12 p-1">
                {/* <p>Variabler - søk i koder</p> */}
                {filtersetting === "Naturtyper" ?
                <>
                  <p className="m-0 lh-38px">Gruppe</p>
                  <div className="text-left">
                    <Select className="mb-2px" styles={customStyles} options={variablergruppeListArr} isClearable="true" onChange={(e) => statechange(setSelectedFilterVariablergruppe, e)} placeholder="Velg gruppe" value={selectedFilterVariablergruppe}></Select>
                  </div>
                  <p className="m-0 lh-38px">Tema</p>
                  <div className="text-left">
                    <Select className="mb-2px" styles={customStyles} options={variablertemaListArr} isDisabled={variablertemadisabled} isClearable="true" onChange={(e) => statechange(setSelectedFilterVariablertema, e)} placeholder="Velg tema" value={selectedFilterVariablertema}></Select>
                  </div>
                  <p className="m-0 lh-38px">Navn</p>
                  <div className="text-left">
                    <Select className="mb-2px" styles={customStyles} options={variablernavnListArr} isDisabled={variablernavndisabled} isClearable="true" onChange={(e) => statechange(setSelectedFilterVariablernavn, e)} placeholder="Velg navn" value={selectedFilterVariablernavn}></Select>
                  </div>
                  <p className="m-0 lh-38px">Trinn</p>
                  <div className="text-left">
                    <Select className="mb-2px" styles={customStyles} options={variablertrinnListArr} isDisabled={variablertrinndisabled} isClearable="true" onChange={(e) => statechange(setSelectedFilterVariablertrinn, e)} placeholder="Velg trinn" value={selectedFilterVariablertrinn}></Select>
                  </div>
                </>
                : 
                <>
                  <p className="m-0 lh-38px">Navn</p>
                  <div className="text-left">
                    <Select className="mb-2px" styles={customStyles} options={variablernavnListArrVern} isClearable="true" onChange={(e) => statechange(setSelectedFilterVariablernavnVern, e)} placeholder="Velg navn" value={selectedFilterVariablernavnVern}></Select>
                  </div>
                  <p className="m-0 lh-38px">Trinn</p>
                  <div className="text-left">
                    <Select className="mb-2px" styles={customStyles} options={variablertrinnListArr} isDisabled={variablertrinndisabled} isClearable="true" onChange={(e) => statechange(setSelectedFilterVariablertrinn, e)} placeholder="Velg trinn" value={selectedFilterVariablertrinn}></Select>
                  </div>
                </>
                }
                <div className="mt-2 kodecontainer">
                  <div className="col-2 text-left">
                    <p className="m-0 lh-38px">Kode</p>
                  </div>
                  <div className="col-10 text-right">
                    <p className="m-0 lh-38px">{selectedFilterVariablerkode}</p>
                  </div>
                </div>
                <button className="btn btn-primary btn-sm btn-block filterbutton" onClick={(e) => addFilter(setSelectedFilterVariabler, selectedFilterVariabler, selectedFilterVariablerkode, selectRefv)}>Legg til filter</button>
              </div>
              </TabPanel>
              <TabPanel className="maintabpanel">
              <button className="btn btn-sm btn-back w-100" onClick={() => setTabIndex(0)}><img className="m-2" src={backarrow} alt="collapseminus" height="12"/>Tilbake</button>
              <div className="col-12 mt-3 d-flex justify-content-flex-center">
                <p className="mediumtext p-2 m-0">Velg ULKM variabler</p><hr className="w-100 ml-2 mr-2"/>
              </div>
              <div className="col-12 mt-2">
                <p>Fritekstsøk (du kan bruke flere ord)</p>
                <div className="text-left">
                  <Select styles={customStyles} ref={(ref) => { setselectRefu(ref); }} openMenuOnFocus={true} options={ulkmListArr} isMulti isClearable="true" dafaultValue="0" onChange={(e) => statechange(setSelectedFilterUlkm, e)} placeholder="ULKMVariabel" value={selectedFilterUlkm}></Select>
                </div>
              </div>
              <div className="col-12 mt-2 d-flex justify-content-flex-center">
                <p className="mediumtext p-2 m-0">Velg ULKM variabler</p><hr className="w-100 ml-2 mr-2"/>
              </div>
              <div className="col-12 p-1">
                {/* <p>ULKM variabler - søk i koder (Kun Vern naturområder NIN)</p> */}
                <p className="m-0 lh-38px">NiNHovedtypegruppe</p>
                <div className="text-left">
                  <Select className="mb-2px" styles={customStyles} options={ulkmNiNHovedtypegruppeListArr} isDisabled={false} isClearable="true" onChange={(e) => statechange(setSelectedFilterUlkmNiNHovedtypegruppe, e)} placeholder="Velg NiNHovedtypegruppe" value={selectedFilterUlkmNiNHovedtypegruppe}></Select>
                </div>
                <p className="m-0 lh-38px">NiNHovedtype</p>
                <div className="text-left">
                  <Select className="mb-2px" styles={customStyles} options={ulkmNiNHovedtypeListArr} isDisabled={ulkmNiNHovedtypedisabled} isClearable="true" onChange={(e) => statechange(setSelectedFilterUlkmNiNHovedtype, e)} placeholder="Velg NiNHovedtype" value={selectedFilterUlkmNiNHovedtype}></Select>
                </div>
                <p className="m-0 lh-38px">Gradientkode</p>
                <div className="text-left">
                  <Select className="mb-2px" styles={customStyles} options={ulkmgradientkodeListArr} isDisabled={ulkmgradientkodedisabled} isClearable="true" onChange={(e) => statechange(setSelectedFilterUlkmgradientkode, e)} placeholder="Velg gradientkode" value={selectedFilterUlkmgradientkode}></Select>  
                </div>
                <p className="m-0 lh-38px">Trinn</p>
                <div className="text-left">
                  <Select className="mb-2px" styles={customStyles} options={ulkmtrinnListArr} isDisabled={ulkmtrinndisabled} isClearable="true" onChange={(e) => statechange(setSelectedFilterUlkmtrinn, e)} placeholder="Velg trinn" value={selectedFilterUlkmtrinn}></Select>
                </div>
                <div className="mt-2 kodecontainer">
                  <div className="col-2 text-left">
                    <p className="m-0 lh-38px">Kode</p>
                  </div>
                  <div className="col-10 text-right">
                    <p className="m-0 lh-38px">{selectedFilterUlkmkode}</p>
                  </div>
                </div>
                <button className="btn btn-primary btn-sm btn-block filterbutton" onClick={(e) => addFilter(setSelectedFilterUlkm, selectedFilterUlkm, selectedFilterUlkmkode, selectRefu)}>Legg til filter</button>
              </div>
              </TabPanel>
              <TabPanel className="maintabpanel">
              <button className="btn btn-sm btn-back w-100" onClick={() => setTabIndex(0)}><img className="m-2" src={backarrow} alt="collapseminus" height="12"/>Tilbake</button>
              <div className="col-12 mt-3 d-flex justify-content-flex-center">
                <p className="mediumtext p-2 m-0">Velg Hovedøkosystem</p><hr className="w-100 ml-2 mr-2"/>
              </div>
              {/* <div className="col-12 mt-2">
                <Select styles={customStyles} options={hovedokosystemListArr} isMulti isClearable="true" onChange={(e) => statechange(setSelectedFilterHovedokosystem, e)} placeholder="Søk i hovedøkosystemer" value={selectedFilterHovedokosystem}></Select>
              </div> */}
              <div className="col-12 p-2 smallmediumtextblack">
                {hovedokosystemListArr && hovedokosystemListArr.length > 0 ? (<div>{hovedokosystemListArr.map((object, i) => 
                <div className="form-check" key={i} >
                  <input className="form-check-input" type="checkbox" name={object.label} value={object} defaultChecked={selectedFilterHovedokosystem && selectedFilterHovedokosystem.length > 0 && selectedFilterHovedokosystem.includes(object) ? true : false} id={object.label} 
                  onClick={(e) => statechangelist(setSelectedFilterHovedokosystem, selectedFilterHovedokosystem, e, object)}/>
                  <label className="form-check-label" htmlFor={object.label}>
                    <p>{object.label}</p>
                  </label>
                </div>
                )}</div>) : "" }
              </div>
              </TabPanel>
              <TabPanel className="maintabpanel">
              <button className="btn btn-sm btn-back w-100" onClick={() => setTabIndex(0)}><img className="m-2" src={backarrow} alt="collapseminus" height="12"/>Tilbake</button>
              <div className="col-12 mt-3 d-flex justify-content-flex-center">
                <p className="mediumtext p-2 m-0">Velg kartleggingsår</p><hr className="w-100 ml-2 mr-2"/>
              </div>
              {/* <div className="col-12 mt-2">
                <Select styles={customStyles} options={kartleggingsaarListArr} isMulti isClearable="true" onChange={(e) => statechange(setSelectedFilterKartleggingsaar, e)} placeholder="Søk i kartleggingsår" value={selectedFilterKartleggingsaar}></Select>
              </div> */}
              <div className="col-12 p-2 smallmediumtextblack">
                {kartleggingsaarListArr && kartleggingsaarListArr.length > 0 ? (<div>{kartleggingsaarListArr.map((object, i) => 
                <div className="form-check" key={i} >
                  <input className="form-check-input" type="checkbox" name={object.label} value={object} defaultChecked={selectedFilterKartleggingsaar && selectedFilterKartleggingsaar.length > 0 && selectedFilterKartleggingsaar.includes(object) ? true : false} id={object.label} 
                  onClick={(e) => statechangelist(setSelectedFilterKartleggingsaar, selectedFilterKartleggingsaar, e, object)}/>
                  <label className="form-check-label" htmlFor={object.label}>
                    <p>{object.label}</p>
                  </label>
                </div>
                )}</div>) : "" }
              </div>
              </TabPanel>
              <TabPanel className="maintabpanel">
              <button className="btn btn-sm btn-back w-100" onClick={() => setTabIndex(0)}><img className="m-2" src={backarrow} alt="collapseminus" height="12"/>Tilbake</button>
              <div className="col-12 mt-3 d-flex justify-content-flex-center">
                <p className="mediumtext p-2 m-0">Velg oppdragsgiver</p><hr className="w-100 ml-2 mr-2"/>
              </div>
              <div className="col-12 mt-2">
                <Select styles={customStyles} options={oppdragsgiverListArr} isMulti isClearable="true" onChange={(e) => statechange(setSelectedFilterOppdragsgiver, e)} placeholder="Søk i oppdragsgivere" value={selectedFilterOppdragsgiver}></Select>
              </div>
              <div className="col-12 p-2 smallmediumtextblack">
                {oppdragsgiverListArr && oppdragsgiverListArr.length > 0 ? (<div>{oppdragsgiverListArr.map((object, i) => 
                <div className="form-check" key={i} >
                  <input className="form-check-input" type="checkbox" name={object.label} value={object} defaultChecked={selectedFilterOppdragsgiver && selectedFilterOppdragsgiver.length > 0 && selectedFilterOppdragsgiver.includes(object) ? true : false} id={object.label} 
                  onClick={(e) => statechangelist(setSelectedFilterOppdragsgiver, selectedFilterOppdragsgiver, e, object)}/>
                  <label className="form-check-label" htmlFor={object.label}>
                    <p>{object.label}</p>
                  </label>
                </div>
                )}</div>) : "" }
              </div>
              </TabPanel>
              <TabPanel className="maintabpanel">
              <button className="btn btn-sm btn-back w-100" onClick={() => setTabIndex(0)}><img className="m-2" src={backarrow} alt="collapseminus" height="12"/>Tilbake</button>
              <div className="col-12 mt-3 d-flex justify-content-flex-center">
                <p className="mediumtext p-2 m-0">Velg oppdragstaker</p><hr className="w-100 ml-2 mr-2"/>
              </div>
              <div className="col-12 mt-2">
                <Select styles={customStyles} options={oppdragstakerListArr} isMulti isClearable="true" onChange={(e) => statechange(setSelectedFilterOppdragstaker, e)} placeholder="Søk i oppdragstakere" value={selectedFilterOppdragstaker}></Select>
              </div>
              <div className="col-12 p-2 smallmediumtextblack">
                {oppdragstakerListArr && oppdragstakerListArr.length > 0 ? (<div>{oppdragstakerListArr.map((object, i) => 
                <div className="form-check" key={i} >
                  <input className="form-check-input" type="checkbox" name={object.label} value={object} defaultChecked={selectedFilterOppdragstaker && selectedFilterOppdragstaker.length > 0 && selectedFilterOppdragstaker.includes(object) ? true : false} id={object.label}
                  onClick={(e) => statechangelist(setSelectedFilterOppdragstaker, selectedFilterOppdragstaker, e, object)}/>
                  <label className="form-check-label" htmlFor={object.label}>
                    <p>{object.label}</p>
                  </label>
                </div>
                )}</div>) : "" }
              </div>
              </TabPanel>
              <TabPanel className="maintabpanel">
              <button className="btn btn-sm btn-back w-100" onClick={() => setTabIndex(0)}><img className="m-2" src={backarrow} alt="collapseminus" height="12"/>Tilbake</button>
              <div className="col-12 mt-3 d-flex justify-content-flex-center">
                <p className="mediumtext p-2 m-0">Velg prosjektområde/dekningskart</p><hr className="w-100 ml-2 mr-2"/>
              </div>
              <div className="col-12 mt-2">
                <Select styles={customStyles} options={prosjektomradeListArr} isMulti isClearable="true" onChange={(e) => statechange(setSelectedFilterProsjektomrade, e)} placeholder="Søk i prosjektområder/dekningskart" value={selectedFilterProsjektomrade}></Select>
              </div>
              </TabPanel>
            </Tabs>
          </div>
          {filtersetting !== null ? 
          <div className="searchContainer mt-2">
            {filtersetting === "Naturtyper" ? 
            <button className="btn btn-primary btn-sm btn-block w-100" onClick={() => doRefreshnaturtyper(prev => prev + 1)}>Søk i 'Naturtyper - Miljødirektoratets instruks'</button>
            : "" }
            {filtersetting === "Vern" ? 
            <button className="btn btn-primary btn-sm btn-block w-100" onClick={() => doRefreshvern(prev => prev + 1)}>Søk i 'Natur i Verneområder (NiN)'</button>
            : "" }
          </div>
          : "" }
        </div>
        <Search 
          filtersetting={filtersetting}
          refreshvern={refreshvern}
          refreshnaturtyper={refreshnaturtyper}
          emptyentirefilter={emptyentirefilter}
          selectedFilterFylke={selectedFilterFylke}
          selectedFilterKommune={selectedFilterKommune}
          selectedFilterProsjektomrade={selectedFilterProsjektomrade}  //kun naturtyper
          selectedFilterNaturvernomrade={selectedFilterNaturvernomrade} //kun vern
          // selectedFilterKartleggingsenheterkode={selectedFilterKartleggingsenheterkode}
          selectedFilterKartleggingsenheter={selectedFilterKartleggingsenheter} // (fritekst)
          // selectedFilterVariablerkode={selectedFilterVariablerkode}
          selectedFilterVariabler={selectedFilterVariabler} // (fritekst)
          // selectedFilterUlkmkode={selectedFilterUlkmkode}
          selectedFilterUlkm={selectedFilterUlkm} //kun vern (fritekst)
          selectedFilterKartleggingsaar={selectedFilterKartleggingsaar} 
          selectedFilterOppdragsgiver={selectedFilterOppdragsgiver} 
          selectedFilterOppdragstaker={selectedFilterOppdragstaker} 
          selectedFilterLokalitetskvalitet={selectedFilterLokalitetskvalitet} 
          selectedFilterTilstand={selectedFilterTilstand} 
          selectedFilterNaturmangfold={selectedFilterNaturmangfold} 
          selectedFilterHovedokosystem={selectedFilterHovedokosystem}
          ArealnumberFra={ArealnumberFra}
          ArealnumberTil={ArealnumberTil}
          Merknadsfelt={Merknadsfelt}
          // arealnumber={arealnumber}
          // arealmoreorless={arealmoreorless}
          selectedFilterNaturtyper={selectedFilterNaturtyper}
          UK_Truetstate={UK_Truetstate}
          UK_NærTruetstate={UK_NærTruetstate}
          UK_SpesieltDårligKartlagtstate={UK_SpesieltDårligKartlagtstate}
          UK_SentralØkosystemFunksjonstate={UK_SentralØkosystemFunksjonstate}
          UK_SeBekrivelseNaturtypestate={UK_SeBekrivelseNaturtypestate}
          UK_IngenStatusstate={UK_IngenStatusstate}
          runFunction={runFunction}
        />
      </div>
    );
}

export default Filter;

