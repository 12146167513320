

import React from 'react';
import '../css/header.css';
// import logoMD from '../images/miljodir_logo_2021.png';
import logoMDsvg from '../images/Mdir_Logo_Grønn.svg'


export default function Header(props) {
 
    return (      
      <div id="headerContainer" className="row align-items-center m-0">                
        <div className="col-12 pl-0 d-flex align-items-center">
          <div className="d-inline-block ml-0 pl-0 mr-4">
            <img className="p-2" src={logoMDsvg} alt="logo" height="75" />                                    
          </div>
          <div className="d-inline-block">
            <h3 className="miljodirlogotext">{process.env.REACT_APP_HeaderText}</h3>               
          </div>  
        </div>          
      </div>          
    );
}

