export function getWhereUrlNaturtyper(props) {
  var whereurl = ""
  //Fylker finnes ikke i Naturtyper, så vi leter gjennom kommunelisten på id
  if (props.selectedFilterFylke && props.selectedFilterFylke.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterFylke.forEach(element => {
      whereurl += "Kommuner LIKE '%(" + element.value + "%' OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  if (props.selectedFilterKommune && props.selectedFilterKommune.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterKommune.forEach(element => {
      whereurl += "Kommuner LIKE '%" + element.value + "%' OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  if (props.selectedFilterProsjektomrade && props.selectedFilterProsjektomrade.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterProsjektomrade.forEach(element => {
      whereurl += "prosjektid='" + element.value + "' OR "       
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  // //Kartleggingsenheter
  // if (props.selectedFilterKartleggingsenheterkode && props.selectedFilterKartleggingsenheterkode !== "NA_") {
  //   if(whereurl !== "") {
  //     whereurl += " AND "
  //   }
  //   whereurl += "("
  //   whereurl += "NiNKartleggingsenheter LIKE '%" + props.selectedFilterKartleggingsenheterkode + "%' OR "
  //   whereurl = whereurl.slice(0, -4) //Remove last OR
  //   whereurl += ")"
  // }
  //Kartleggingsenheter (fritekst)
  if (props.selectedFilterKartleggingsenheter && props.selectedFilterKartleggingsenheter.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterKartleggingsenheter.forEach(element => {
      whereurl += "NiNKartleggingsenheter LIKE '%" + element.value + "%' OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  //Variabler //DISABLE TO AVOID DOUBLE FILTER
  // if (props.selectedFilterVariablerkode && props.selectedFilterVariablerkode !== "") {
  //   var selectedFilterVariablerkodeNoMdir = props.selectedFilterVariablerkode.split("Mdir").pop() //'Mdir'-prefix brukes ikke i datagrunnlaget
  //   if(whereurl !== "") {
  //     whereurl += " AND "
  //   }
  //   whereurl += "("
  //   whereurl += "NINBeskrivelsesvariabler LIKE '%" + selectedFilterVariablerkodeNoMdir + "%' OR "
  //   whereurl = whereurl.slice(0, -4) //Remove last OR
  //   whereurl += ")"
  // }
  //Variabler (fritekst)
  if (props.selectedFilterVariabler && props.selectedFilterVariabler.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterVariabler.forEach(element => {
      var selectedFilterVariablerNoMdir = element.value.split("Mdir").pop()
      whereurl += "NINBeskrivelsesvariabler LIKE '%" + selectedFilterVariablerNoMdir + "%' OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  if (props.selectedFilterKartleggingsaar && props.selectedFilterKartleggingsaar.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterKartleggingsaar.forEach(element => {
      whereurl += "Kartleggingsår=" + element.value + " OR "       
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  if (props.selectedFilterOppdragsgiver && props.selectedFilterOppdragsgiver.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterOppdragsgiver.forEach(element => {
      whereurl += "Oppdragsgiver='" + element.value + "' OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  if (props.selectedFilterOppdragstaker && props.selectedFilterOppdragstaker.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterOppdragstaker.forEach(element => {
      whereurl += "Oppdragstaker='" + element.value + "' OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  if (props.ArealnumberFra) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    whereurl += "SHAPE.STArea()>=" + props.ArealnumberFra
    whereurl += ")"
  }
  if (props.ArealnumberTil) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    whereurl += "SHAPE.STArea()<=" + props.ArealnumberTil
    whereurl += ")"
  }
  if (props.selectedFilterNaturtyper && props.selectedFilterNaturtyper.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterNaturtyper.forEach(element => {
      whereurl += "Naturtypekode='" + element.value + "' OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  if (props.selectedFilterLokalitetskvalitet && props.selectedFilterLokalitetskvalitet.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterLokalitetskvalitet.forEach(element => {
      whereurl += "Lokalitetskvalitet=" + element.value + " OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  if (props.selectedFilterTilstand && props.selectedFilterTilstand.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterTilstand.forEach(element => {
      whereurl += "Tilstand=" + element.value + " OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  if (props.selectedFilterNaturmangfold && props.selectedFilterNaturmangfold.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterNaturmangfold.forEach(element => {
      whereurl += "Naturmangfold=" + element.value + " OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  if (props.selectedFilterHovedokosystem && props.selectedFilterHovedokosystem.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterHovedokosystem.forEach(element => {
      whereurl += "Hovedøkosystem='" + element.value + "' OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  if (props.UK_Truetstate && props.UK_Truetstate !== "Ingen filter") {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    if (props.UK_Truetstate === "Ja") {
      whereurl += "UK_Truet = 1"
    }
    else if (props.UK_Truetstate === "Nei") {
      whereurl += "UK_Truet is null"
    }
    whereurl += ")"
  }
  if (props.UK_NærTruetstate && props.UK_NærTruetstate !== "Ingen filter") {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    if (props.UK_NærTruetstate === "Ja") {
      whereurl += "UK_NærTruet = 1"
    }
    else if (props.UK_NærTruetstate === "Nei") {
      whereurl += "UK_NærTruet is null"
    }
    whereurl += ")"
  }
  if (props.UK_SpesieltDårligKartlagtstate && props.UK_SpesieltDårligKartlagtstate !== "Ingen filter") {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    if (props.UK_SpesieltDårligKartlagtstate === "Ja") {
      whereurl += "UK_SpesieltDårligKartlagt = 1"
    }
    else if (props.UK_SpesieltDårligKartlagtstate === "Nei") {
      whereurl += "UK_SpesieltDårligKartlagt is null"
    }
    whereurl += ")"
  }
  if (props.UK_SentralØkosystemFunksjonstate && props.UK_SentralØkosystemFunksjonstate !== "Ingen filter") {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    if (props.UK_SentralØkosystemFunksjonstate === "Ja") {
      whereurl += "UK_SentralØkosystemFunksjon = 1"
    }
    else if (props.UK_SentralØkosystemFunksjonstate === "Nei") {
      whereurl += "UK_SentralØkosystemFunksjon is null"
    }
    whereurl += ")"
  }
  if (props.UK_SeBekrivelseNaturtypestate && props.UK_SeBekrivelseNaturtypestate !== "Ingen filter") {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    if (props.UK_SeBekrivelseNaturtypestate === "Ja") {
      whereurl += "UK_SeBekrivelseNaturtype = 1"
    }
    else if (props.UK_SeBekrivelseNaturtypestate === "Nei") {
      whereurl += "UK_SeBekrivelseNaturtype is null"
    }
    whereurl += ")"
  }
  if (props.UK_IngenStatusstate && props.UK_IngenStatusstate !== "Ingen filter") {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    if (props.UK_IngenStatusstate === "Ja") {
      whereurl += "UK_IngenStatus = 1"
    }
    else if (props.UK_IngenStatusstate === "Nei") {
      whereurl += "UK_IngenStatus is null"
    }
    whereurl += ")"
  }
  return whereurl
}


export function getWhereUrlVern(props) {
  var whereurl = ""
  //Fylke
  if (props.selectedFilterFylke && props.selectedFilterFylke.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterFylke.forEach(element => {
      whereurl += "Fylker LIKE '%" + element.value + "%' OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  //Kommune
  if (props.selectedFilterKommune && props.selectedFilterKommune.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterKommune.forEach(element => {
      whereurl += "Kommuner LIKE '%" + element.value + "%' OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  //Naturvernområde
  if (props.selectedFilterNaturvernomrade && props.selectedFilterNaturvernomrade.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterNaturvernomrade.forEach(element => {
      whereurl += "NaturvernOmrådenavn LIKE '%" + element.value + "%' OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  // //Kartleggingsenheter
  // if (props.selectedFilterKartleggingsenheterkode && props.selectedFilterKartleggingsenheterkode !== "NA_") {
  //   if(whereurl !== "") {
  //     whereurl += " AND "
  //   }
  //   whereurl += "("
  //   whereurl += "Kartleggingsenhetkode LIKE '%" + props.selectedFilterKartleggingsenheterkode + "%' OR "
  //   whereurl = whereurl.slice(0, -4) //Remove last OR
  //   whereurl += ")"
  // }
  //Kartleggingsenheter (fritekst)
  if (props.selectedFilterKartleggingsenheter && props.selectedFilterKartleggingsenheter.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterKartleggingsenheter.forEach(element => {
      whereurl += "Kartleggingsenhetkode LIKE '%" + element.value + "%' OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  // //Variabler
  // if (props.selectedFilterVariablerkode && props.selectedFilterVariablerkode !== "") {
  //   if(whereurl !== "") {
  //     whereurl += " AND "
  //   }
  //   whereurl += "("
  //   whereurl += "Beskrivelsesvariabler LIKE '%" + props.selectedFilterVariablerkode + "%' OR "
  //   whereurl = whereurl.slice(0, -4) //Remove last OR
  //   whereurl += ")"
  // }
  //Variabler (fritekst)
  if (props.selectedFilterVariabler && props.selectedFilterVariabler.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterVariabler.forEach(element => {
      var selectedFilterVariablerNoMdir = element.value.split("Mdir").pop()
      whereurl += "Beskrivelsesvariabler LIKE '%" + selectedFilterVariablerNoMdir + "%' OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  // //Ulkm
  // if (props.selectedFilterUlkmkode && props.selectedFilterUlkmkode !== "") {
  //   if(whereurl !== "") {
  //     whereurl += " AND "
  //   }
  //   whereurl += "("
  //   whereurl += "ULKM LIKE '%" + props.selectedFilterUlkmkode + "%' OR "
  //   whereurl = whereurl.slice(0, -4) //Remove last OR
  //   whereurl += ")"
  // }
  //Ulkm (fritekst)
  if (props.selectedFilterUlkm && props.selectedFilterUlkm.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterUlkm.forEach(element => {
      whereurl += "ULKM LIKE '%" + element.value + "%' OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  //Oppdragstaker
  if (props.selectedFilterOppdragstaker && props.selectedFilterOppdragstaker.length !== 0) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    props.selectedFilterOppdragstaker.forEach(element => {
      whereurl += "Oppdragstaker='" + element.value + "' OR "
    }); 
    whereurl = whereurl.slice(0, -4) //Remove last OR
    whereurl += ")"
  }
  if (props.ArealnumberFra) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    whereurl += "SHAPE.STArea()>=" + props.ArealnumberFra
    whereurl += ")"
  }
  if (props.ArealnumberTil) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    whereurl += "SHAPE.STArea()<=" + props.ArealnumberTil
    whereurl += ")"
  }
  if (props.Merknadsfelt) {
    if(whereurl !== "") {
      whereurl += " AND "
    }
    whereurl += "("
    whereurl += "Områdemerknad LIKE '%" + props.Merknadsfelt + "%'"
    whereurl += ")"
  }
  return whereurl
}

function addtofilterurl(input,inputtekst){
  var filterurl = ""
  if (input && input.length !== 0) {
    //console.log(input)
    filterurl += "&" + inputtekst + "="
    input.forEach(element => {
      if (typeof element.value === 'string' || element.value instanceof String) {
        filterurl += element.value.replaceAll(' ', '_') + ","
      }
      else {
        //console.log(element.value)
        filterurl += element.value.toString() + ","
      }
    }); 
    filterurl = filterurl.slice(0, -1) //Remove last ","
  }
  return filterurl

}

export function getFilterUrlNaturtyper(props) {
  var filterurl = window.location.origin + "?filtersetting=" + props.filtersetting
  //Fylke
  filterurl += addtofilterurl(props.selectedFilterFylke, "fylke")
  //Kommune
  filterurl += addtofilterurl(props.selectedFilterKommune, "kommune")
  //Prosjektområde
  filterurl += addtofilterurl(props.selectedFilterProsjektomrade, "prosjektomrade")
  //Kartleggingsenheter
  filterurl += addtofilterurl(props.selectedFilterKartleggingsenheter, "kartleggingsenheter")
  //Variabler
  filterurl += addtofilterurl(props.selectedFilterVariabler, "variabler")
  //Kartleggingsår
  filterurl += addtofilterurl(props.selectedFilterKartleggingsaar, "kartleggingsaar")
  //Oppdragsgiver
  filterurl += addtofilterurl(props.selectedFilterOppdragsgiver, "oppdragsgiver")
  //Oppdragstaker
  filterurl += addtofilterurl(props.selectedFilterOppdragstaker, "oppdragstaker")
  //ArealFra
  if (props.ArealnumberFra) {
    filterurl += "&arealfra=" + props.ArealnumberFra
  }
  //ArealTil
  if (props.ArealnumberTil) {
    filterurl += "&arealtil=" + props.ArealnumberTil
  }
  //Naturtyper
  filterurl += addtofilterurl(props.selectedFilterNaturtyper, "naturtyper")
  //UK_Truet
  if (props.UK_Truetstate !== "Ingen filter") {
    filterurl += "&UK_Truet=" + props.UK_Truetstate
  }
  //UK_NærTruet
  if (props.UK_NærTruetstate !== "Ingen filter") {
    filterurl += "&UK_NaerTruet=" + props.UK_NærTruetstate
  }
  //UK_SpesieltDårligKartlagt
  if (props.UK_SpesieltDårligKartlagtstate !== "Ingen filter") {
    filterurl += "&UK_SpesieltDaarligKartlagt=" + props.UK_SpesieltDårligKartlagtstate
  }
  //UK_SentralØkosystemFunksjon
  if (props.UK_SentralØkosystemFunksjonstate !== "Ingen filter") {
    filterurl += "&UK_SentralOekosystemFunksjon=" + props.UK_SentralØkosystemFunksjonstate
  }
  //UK_SeBekrivelseNaturtype
  if (props.UK_SeBekrivelseNaturtypestate !== "Ingen filter") {
    filterurl += "&UK_SeBekrivelseNaturtype=" + props.UK_SeBekrivelseNaturtypestate
  }
  //UK_IngenStatus
  if (props.UK_IngenStatusstate !== "Ingen filter") {
    filterurl += "&UK_IngenStatus=" + props.UK_IngenStatusstate
  }
  //Lokalitetskvalitet
  filterurl += addtofilterurl(props.selectedFilterLokalitetskvalitet, "lokalitetskvalitet")
  //Tilstand
  filterurl += addtofilterurl(props.selectedFilterTilstand, "tilstand")
  //Naturmangfold
  filterurl += addtofilterurl(props.selectedFilterNaturmangfold, "naturmangfold")
  //Hovedøkosystem
  filterurl += addtofilterurl(props.selectedFilterHovedokosystem, "hovedokosystem")
  return filterurl
}

export function getFilterUrlVern(props) {
  var filterurl = window.location.origin + "?filtersetting=" + props.filtersetting
  //Fylke
  filterurl += addtofilterurl(props.selectedFilterFylke, "fylke")
  //Kommune
  filterurl += addtofilterurl(props.selectedFilterKommune, "kommune")
  //Naturvernområde
  filterurl += addtofilterurl(props.selectedFilterNaturvernomrade, "naturvernomrade")
  //Kartleggingsenheter
  filterurl += addtofilterurl(props.selectedFilterKartleggingsenheter, "kartleggingsenheter")
  //Variabler
  filterurl += addtofilterurl(props.selectedFilterVariabler, "variabler")
  //ULKM
  filterurl += addtofilterurl(props.selectedFilterUlkm, "ulkm")
  //Oppdragstaker
  filterurl += addtofilterurl(props.selectedFilterOppdragstaker, "oppdragstaker")
  //ArealFra
  if (props.ArealnumberFra) {
    filterurl += "&arealfra=" + props.ArealnumberFra
  }
  //ArealTil
  if (props.ArealnumberTil) {
    filterurl += "&arealtil=" + props.ArealnumberTil
  }  
  //Merknadsfelt
  if (props.Merknadsfelt) {
    filterurl += "&merknadsfelt=" + props.Merknadsfelt
  }
  return filterurl
}

export function updatefilteroversikt(props) {
  var newfiltertargets = []; //Start fresh
  for (var k in props) {
    if (Array.isArray(props[k]) && props[k].length !== 0) {
      let newk = k;
      props[k].forEach(element => {
        newfiltertargets.push({label: newk.replace("selectedFilter", "") + ": " + element.label, value: element.value, origin: newk})
        // if (!newfiltertargets.includes(element)) {
        //   newfiltertargets.push({label: newk + ": " + element.label, value: element.value})
        // }
      })
    }
    else if (typeof props[k] === "string" && props[k] !== "" && props[k] !== "Ingen filter" && k !== "filtersetting") {
      var element2 = { label: k + ": " + props[k], value: props[k], origin: k }
      if (!newfiltertargets.includes(element2)) {
        newfiltertargets.push(element2)
      }
    }
  }
  return newfiltertargets
}
