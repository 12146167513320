import React, { useEffect, useRef, useState } from 'react';
import '../css/map.css';
import { loadModules } from 'esri-loader';

function EsriMap(props) {
    const mapRef = useRef();

    // Local state
    const [mapViewInstance, setMapViewInstance] = useState(null);
    const [mapInstance, setMapInstance] = useState(null); 

    useEffect( //Clear result when switching tabs
      () => {
        console.log("Map 2 triggered")
        // lazy load the required ArcGIS API for JavaScript modules and CSS
        loadModules(
          [
            'esri/Map',
            'esri/views/MapView',
            "esri/WebMap",
            "esri/layers/TileLayer",
            "esri/geometry/Extent"
          ], { css: true })
        .then((
          [
            ArcGISMap,
            MapView,
            WebMap,
            TileLayer,
            Extent       
          ]) => {

          let map;

          if (props.filtersetting === "Vern") {
            map = new WebMap({
              portalItem: {
                //id: "a758ae6e84f84b3fba426d14d7cc9d4b" //Naturtyper Webmap
                id: "040793f6a7974c189cf0449cf6b0ffe5" //Vern Webmap
              }
            });
          }
          // else if (props.filtersetting === "Naturtyper") {
          else {
            map = new WebMap({
              portalItem: {
                id: "d37d80726a364384901fb767d4ca2b99" //Naturtyper Webmap
                //id: "8318c5f1ab8944bc856cfd618f584cea" //Vern Webmap
              }
            });
          }

          //Legg til bildekart fra AGOL som TileLayer
          let bildelayer = new TileLayer({
            url: "https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_EUREF89/GeocacheBilder/MapServer"
          });
          bildelayer.visible = false;
          bildelayer.id = "GeocacheBilder";
          map.add(bildelayer)
          
          setMapInstance(map);

          const view = new MapView({
            container: mapRef.current,
            map: map,        
            extent: new Extent({
              xmin: 150000,
              ymin: 6700000,
              xmax: 300000,
              ymax: 7700000,
              spatialReference: { wkid: 25833 },
            }),
            constraints: { rotationEnabled: false },
            // center: [-118, 34],
            // zoom: 8
          });

          view.ui.move("zoom", "bottom-right");

          view.when(function () {                            
            
          });
          setMapViewInstance(view);
          
          return () => {
            if (view) {

              // destroy the map view
              view.container = null;
            }
          };
        });
      }, [props.filtersetting])

    useEffect( //Clear result when switching tabs
      () => {
        if(mapInstance){ //Crash prevention
          for (var i=0; i<mapInstance.allLayers.items.length; i++) {
            var layer = mapInstance.allLayers.items[i]
            if (layer.id === "vern_naturomrader_nin_6751" || layer.id === "vern_naturomrader_nin_3805"){ //Vern
              layer.minScale = 999999999
              layer.definitionExpression = props.sql
            }
            else if (layer.id === "naturtyper_nin_9263" || layer.id === "naturtyper_nin_8699"){ //Naturtyper
              layer.minScale = 999999999
              layer.definitionExpression = props.sql
            }
          }
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.sql])

    useEffect( //For "Zoomto"
      () => {
        console.log("Trigger zoom Til")
        loadModules(
          [
            'esri/geometry/Polygon',
            'esri/layers/support/FeatureFilter',
            'esri/Graphic',
            'esri/symbols/SimpleFillSymbol',
          ], { css: true })
          .then((
            [
              Polygon,
              FeatureFilter,
              Graphic,
              SimpleFillSymbol,
            ]) => { 
          if(mapInstance && mapViewInstance){ 
            for (var i=0; i<mapInstance.allLayers.items.length; i++) {
              var layer = mapInstance.allLayers.items[i]
              if (layer.id === "vern_naturomrader_nin_6751"){ //Vern
                mapViewInstance.whenLayerView(layer).then((layerView) => {
                  const polygon = new Polygon({
                    rings: props.mapcenter[0].rings,
                    spatialReference: mapViewInstance.spatialReference
                  });
                  if (layerView) {
                    var filter = new FeatureFilter({
                      where: "Område5kid='" + props.mapcenter[1] + "'",
                    });
                    layerView.featureEffect = {
                      filter: filter,
                      includedEffect: "brightness(15) drop-shadow(0, 0px, 12px) hue-rotate(270deg)"
                    };
                  }
                  mapViewInstance.goTo(polygon);
                })
              }
              else if (layer.id === "naturtyper_nin_8699"){ //Naturtyper
                mapViewInstance.whenLayerView(layer).then((layerView) => {
                  const polygon = new Polygon({
                    rings: props.mapcenter[0].rings,
                    spatialReference: mapViewInstance.spatialReference
                  });
                  if (layerView) {
                    var filter = new FeatureFilter({
                      where: "Områdenavn='" + props.mapcenter[1] + "'",
                    });
                    layerView.featureEffect = {
                      filter: filter,
                      includedEffect: "brightness(1.5) drop-shadow(0, 0px, 12px) hue-rotate(90deg)"
                    };
                  }
                  mapViewInstance.goTo(polygon);
                })
              }
            }
          } 
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.mapcenter])

  const toggle = (mapInstance) => {
    var basemapIsBilder = !document.getElementById("basemapToggle").classList.contains("basemapToggleBilder");
    document.getElementById("basemapToggle").classList.toggle("basemapToggleBilder");
    if (basemapIsBilder) {
        document.getElementById("basemapToggleText").innerHTML = "Fargekart";
    }
    else {
        document.getElementById("basemapToggleText").innerHTML = "Flyfoto";
    }
    if(mapInstance){ //Crash prevention
        for (var i=0; i<mapInstance.allLayers.items.length; i++) {
            var layer = mapInstance.allLayers.items[i]

            if (basemapIsBilder) {
                if (layer.id.indexOf("GeocacheBilder") > -1) {
                    layer.visible = true;
                }
                if (layer.id.indexOf("GeocacheBasis") > -1) {
                    layer.visible = false;
                }
            }
            else {
                if (layer.id.indexOf("GeocacheBilder") > -1) {
                    layer.visible = false;
                }
                if (layer.id.indexOf("GeocacheBasis") > -1) {
                    layer.visible = true;
                }
            }

        }
    }
};

  return (
      <div className="kartdiv">
        <div className="webmap" ref={mapRef}>      
          {/* <Search /> */}
          {/* <div id="mousefollowinfo" style={{display: 'none'}}>Mousefollowertext</div> */}
        </div>
        <div id="basemapToggle" className="basemapToggle" onClick={() => {toggle(mapInstance)}}>
          <div id="basemapToggleText" className="basemapToggleText">Flyfoto</div>
        </div>
      </div>

  );

}

export default EsriMap;
