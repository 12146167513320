import React, { useState, useEffect } from 'react';

import '../css/search.css';

import { loadModules } from 'esri-loader';
import Select, { components }  from 'react-select';
import Result from './Result'

import { getWhereUrlNaturtyper, getWhereUrlVern, getFilterUrlNaturtyper, getFilterUrlVern, updatefilteroversikt } from '../helpers.js'

export default function Search(props) {

    const [resultjson, setResultJson] = useState(null);
    const [sql, setSql] = useState("");
    const [filterurl, setFilterurl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [filtertargets, setFiltertargets] = useState([]);

    useEffect(() => {
      searchvern(); //children function of interest
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refreshvern]);

    useEffect(() => {
      searchnaturtyper(); //children function of interest
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refreshnaturtyper]);

    useEffect(() => { //Update filter
      var newfiltertargets = updatefilteroversikt(props)
      setFiltertargets(newfiltertargets)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const searchnaturtyper = () => {
      console.log("Searching...")
      setIsLoading(true);
      //Naturtyper NIN (Alle)
      const url = "https://arcgis06.miljodirektoratet.no/arcgis/rest/services/faktaark/naturtyper_nin/MapServer/0"

      var whereurl = getWhereUrlNaturtyper(props)
      var filterurl = getFilterUrlNaturtyper(props)

      setSql(whereurl);
      setFilterurl(filterurl);

      if (whereurl !== "") {
        loadModules(
          [
            "esri/rest/support/Query", "esri/rest/query" //query unngår på magisk vis CORS-problematikk
          ], { css: true })
        .then((
          [
            Query,
            query         
          ]) => {
          var queryn = new Query();
          queryn.returnGeometry = true;
          queryn.outFields = "OBJECTID,Områdenavn,Naturtype,Faktaark";
          queryn.where = whereurl;
          queryn.returnDistinctValues = false;
          query.executeQueryJSON(url,queryn).then(result => {
            setResultJson(JSON.stringify(result));
            setIsLoading(false);
          })
          .catch(ex => {
            setIsLoading(false);
            setResultJson(JSON.stringify({error: ex.message}));
            console.log(ex);
          });
        });
      }
      else {
        setResultJson(JSON.stringify({error: 'Ingen filter er valgt'}));
        setIsLoading(false);
      }

    }

    const searchvern = () => {
      console.log("Searching...")
      setIsLoading(true);

      //Vern_Naturomrader_NiN (Alle)
      const url = "https://arcgis06.miljodirektoratet.no/arcgis/rest/services/faktaark/vern_naturomrader_nin/MapServer/0"
      
      var whereurl = getWhereUrlVern(props)
      var filterurl = getFilterUrlVern(props);

      setSql(whereurl);
      setFilterurl(filterurl);
      
      if (whereurl !== "") {
        loadModules(
          [
            "esri/rest/support/Query", "esri/rest/query" //query unngår på magisk vis CORS-problematikk
          ], { css: true })
        .then((
          [
            Query,
            query         
          ]) => {

          var queryv = new Query();
          queryv.returnGeometry = true;
          queryv.outFields = "OBJECTID,NaturvernOmrådenavn,Område5kid,Hovedtypebeskrivelse,Kartleggingsenhetbeskrivelse,Kartleggingsenhetkode,Faktaark";
          queryv.where = whereurl;
          queryv.returnDistinctValues = false;
          query.executeQueryJSON(url,queryv).then(result => {
            setResultJson(JSON.stringify(result));
            setIsLoading(false);
          })
          .catch(ex => {
            setIsLoading(false);
            console.log(ex);
          });
        });
      }
      else {
        setResultJson(JSON.stringify({error: 'Ingen filter er valgt'}));
        setIsLoading(false);
      }
    }

    return (
      <div className="resultdiv">
        <div className="col-12 mt-2 d-flex justify-content-flex-center">
          <p className="mediumtext p-2 m-0">Søkekriterier</p><hr className="w-100 ml-2 mr-2"/>
        </div>
        <div className="col-12 p-2">
          <Select 
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
              MenuList: () => null,
              Menu: () => null,
              MultiValueRemove: (myprops) => <div className="custommultivalueremove m-auto" onClick={(e) => {props.runFunction(myprops.data.origin, myprops.data.value)}}><components.MultiValueRemove></components.MultiValueRemove></div>,
              ClearIndicator: () =>  <div><button onClick={(e) => props.emptyentirefilter()} className="btn btn-sm">(Tøm hele filteret)</button></div>,
            }}
            options={[]}
            isMulti
            isClearable="true"
            placeholder="Filter"
            isSearchable={false}
            value={filtertargets}>
          </Select>
        </div>
        <Result resultjson={resultjson} filtersetting={props.filtersetting} sql={sql} filterurl={filterurl} isLoading={isLoading}/>
      </div>
    );
}
