//Denne listen kommer fra MD, og inneholder alle variabelkoder som er i bruk i 'Natur i Verneområder (NiN)'
//Kun kodene i denne listen skal vises i Variabelsøket (vern) i NiN-sokoganalyse-klient

module.exports = [
    "'1AG-A-0_0'",
    "'1AG-A-0_1'",
    "'1AG-A-0_2'",
    "'1AG-A-0_3'",
    "'1AG-A-0_4'",
    "'1AG-A-0_5'",
    "'1AG-A-0_6'",
    "'1AG-A-0_7'",
    "'1AG-A-0_8'",
    "'1AG-B_0'",
    "'1AG-B_1'",
    "'1AG-B_2'",
    "'1AG-B_3'",
    "'1AG-B_4'",
    "'1AG-B_5'",
    "'1AG-B_6'",
    "'1AG-B_7'",
    "'1AG-B_8'",
    "'1AR-D-L_0'",
    "'1AR-D-L_1'",
    "'1AR-D-L_2'",
    "'1AR-D-L_3'",
    "'1AR-D-L_4'",
    "'1AR-D-M_0'",
    "'1AR-D-M_1'",
    "'1AR-D-M_2'",
    "'1AR-D-M_3'",
    "'1AR-D-M_4'",
    "'1AR-B-ULgl_0'",
    "'1AR-B-ULgl_1'",
    "'1AR-B-ULgl_2'",
    "'1AR-B-ULgl_3'",
    "'1AR-B-ULgl_4'",
    "'1AR-B-L_0'",
    "'1AR-B-L_1'",
    "'1AR-B-L_2'",
    "'1AR-B-L_3'",
    "'1AR-B-L_4'",
    "'1AR-B-V_0'",
    "'1AR-B-V_1'",
    "'1AR-B-V_2'",
    "'1AR-B-V_3'",
    "'1AR-B-V_4'",
    "'1AR-B-SOzz_0'",
    "'1AR-B-SOzz_1'",
    "'1AR-B-SOzz_2'",
    "'1AR-B-SOzz_3'",
    "'1AR-B-SOzz_4'",
    "'1AR-B-FRex_0'",
    "'1AR-B-FRex_1'",
    "'1AR-B-FRex_2'",
    "'1AR-B-FRex_3'",
    "'1AR-B-FRex_4'",
    "'1AR-B-TAba_0'",
    "'1AR-B-TAba_1'",
    "'1AR-B-TAba_2'",
    "'1AR-B-TAba_3'",
    "'1AR-B-TAba_4'",
    "'1AR-B-B_0'",
    "'1AR-B-B_1'",
    "'1AR-B-B_2'",
    "'1AR-B-B_3'",
    "'1AR-B-B_4'",
    "'1AR-B-BEvu_0'",
    "'1AR-B-BEvu_1'",
    "'1AR-B-BEvu_2'",
    "'1AR-B-BEvu_3'",
    "'1AR-B-BEvu_4'",
    "'1AR-B-BEpu_0'",
    "'1AR-B-BEpu_1'",
    "'1AR-B-BEpu_2'",
    "'1AR-B-BEpu_3'",
    "'1AR-B-BEpu_4'",
    "'1AR-B-BE_0'",
    "'1AR-B-BE_1'",
    "'1AR-B-BE_2'",
    "'1AR-B-BE_3'",
    "'1AR-B-BE_4'",
    "'1AR-B-RUzz_0'",
    "'1AR-B-RUzz_1'",
    "'1AR-B-RUzz_2'",
    "'1AR-B-RUzz_3'",
    "'1AR-B-RUzz_4'",
    "'1AR-B-RUid_0'",
    "'1AR-B-RUid_1'",
    "'1AR-B-RUid_2'",
    "'1AR-B-RUid_3'",
    "'1AR-B-RUid_4'",
    "'1AR-B-FAsy_0'",
    "'1AR-B-FAsy_1'",
    "'1AR-B-FAsy_2'",
    "'1AR-B-FAsy_3'",
    "'1AR-B-FAsy_4'",
    "'1AR-B-SAda_0'",
    "'1AR-B-SAda_1'",
    "'1AR-B-SAda_2'",
    "'1AR-B-SAda_3'",
    "'1AR-B-SAda_4'",
    "'1AR-B-AB_0'",
    "'1AR-B-AB_1'",
    "'1AR-B-AB_2'",
    "'1AR-B-AB_3'",
    "'1AR-B-AB_4'",
    "'1AR-B-E_0'",
    "'1AR-B-E_1'",
    "'1AR-B-E_2'",
    "'1AR-B-E_3'",
    "'1AR-B-E_4'",
    "'1AR-B-QU_0'",
    "'1AR-B-QU_1'",
    "'1AR-B-QU_2'",
    "'1AR-B-QU_3'",
    "'1AR-B-QU_4'",
    "'1AR-B-JUco_0'",
    "'1AR-B-JUco_1'",
    "'1AR-B-JUco_2'",
    "'1AR-B-JUco_3'",
    "'1AR-B-JUco_4'",
    "'1AR-B-MAsy_0'",
    "'1AR-B-MAsy_1'",
    "'1AR-B-MAsy_2'",
    "'1AR-B-MAsy_3'",
    "'1AR-B-MAsy_4'",
    "'1AR-B-PUsy_0'",
    "'1AR-B-PUsy_1'",
    "'1AR-B-PUsy_2'",
    "'1AR-B-PUsy_3'",
    "'1AR-B-PUsy_4'",
    "'1AR-B-RHca_0'",
    "'1AR-B-RHca_1'",
    "'1AR-B-RHca_2'",
    "'1AR-B-RHca_3'",
    "'1AR-B-RHca_4'",
    "'1AR-B-PIab_0'",
    "'1AR-B-PIab_1'",
    "'1AR-B-PIab_2'",
    "'1AR-B-PIab_3'",
    "'1AR-B-PIab_4'",
    "'1AR-B-ALin_0'",
    "'1AR-B-ALin_1'",
    "'1AR-B-ALin_2'",
    "'1AR-B-ALin_3'",
    "'1AR-B-ALin_4'",
    "'1AR-B-CYsc_0'",
    "'1AR-B-CYsc_1'",
    "'1AR-B-CYsc_2'",
    "'1AR-B-CYsc_3'",
    "'1AR-B-CYsc_4'",
    "'1AR-B-CR_0'",
    "'1AR-B-CR_1'",
    "'1AR-B-CR_2'",
    "'1AR-B-CR_3'",
    "'1AR-B-CR_4'",
    "'1AR-B-COav_0'",
    "'1AR-B-COav_1'",
    "'1AR-B-COav_2'",
    "'1AR-B-COav_3'",
    "'1AR-B-COav_4'",
    "'1AR-B-PRpa_0'",
    "'1AR-B-PRpa_1'",
    "'1AR-B-PRpa_2'",
    "'1AR-B-PRpa_3'",
    "'1AR-B-PRpa_4'",
    "'1AR-B-BEpe_0'",
    "'1AR-B-BEpe_1'",
    "'1AR-B-BEpe_2'",
    "'1AR-B-BEpe_3'",
    "'1AR-B-BEpe_4'",
    "'1AR-B-AEhi_0'",
    "'1AR-B-AEhi_1'",
    "'1AR-B-AEhi_2'",
    "'1AR-B-AEhi_3'",
    "'1AR-B-AEhi_4'",
    "'1AR-B-SM_0'",
    "'1AR-B-SM_1'",
    "'1AR-B-SM_2'",
    "'1AR-B-SM_3'",
    "'1AR-B-SM_4'",
    "'1AR-B-PUzz_0'",
    "'1AR-B-PUzz_1'",
    "'1AR-B-PUzz_2'",
    "'1AR-B-PUzz_3'",
    "'1AR-B-PUzz_4'",
    "'1AR-B-PIzz_0'",
    "'1AR-B-PIzz_1'",
    "'1AR-B-PIzz_2'",
    "'1AR-B-PIzz_3'",
    "'1AR-B-PIzz_4'",
    "'1AR-B-SAzz_0'",
    "'1AR-B-SAzz_1'",
    "'1AR-B-SAzz_2'",
    "'1AR-B-SAzz_3'",
    "'1AR-B-SAzz_4'",
    "'1AR-B-POzz_0'",
    "'1AR-B-POzz_1'",
    "'1AR-B-POzz_2'",
    "'1AR-B-POzz_3'",
    "'1AR-B-POzz_4'",
    "'1AR-B-SApe_0'",
    "'1AR-B-SApe_1'",
    "'1AR-B-SApe_2'",
    "'1AR-B-SApe_3'",
    "'1AR-B-SApe_4'",
    "'1AR-B-PRce_0'",
    "'1AR-B-PRce_1'",
    "'1AR-B-PRce_2'",
    "'1AR-B-PRce_3'",
    "'1AR-B-PRce_4'",
    "'1AR-B-MRge_0'",
    "'1AR-B-MRge_1'",
    "'1AR-B-MRge_2'",
    "'1AR-B-MRge_3'",
    "'1AR-B-MRge_4'",
    "'1AR-B-ILaq_0'",
    "'1AR-B-ILaq_1'",
    "'1AR-B-ILaq_2'",
    "'1AR-B-ILaq_3'",
    "'1AR-B-ILaq_4'",
    "'1AR-B-VIop_0'",
    "'1AR-B-VIop_1'",
    "'1AR-B-VIop_2'",
    "'1AR-B-VIop_3'",
    "'1AR-B-VIop_4'",
    "'1AR-B-LOxy_0'",
    "'1AR-B-LOxy_1'",
    "'1AR-B-LOxy_2'",
    "'1AR-B-LOxy_3'",
    "'1AR-B-LOxy_4'",
    "'1AR-B-LA_0'",
    "'1AR-B-LA_1'",
    "'1AR-B-LA_2'",
    "'1AR-B-LA_3'",
    "'1AR-B-LA_4'",
    "'1AR-B-LIvu_0'",
    "'1AR-B-LIvu_1'",
    "'1AR-B-LIvu_2'",
    "'1AR-B-LIvu_3'",
    "'1AR-B-LIvu_4'",
    "'1AR-B-TIco_0'",
    "'1AR-B-TIco_1'",
    "'1AR-B-TIco_2'",
    "'1AR-B-TIco_3'",
    "'1AR-B-TIco_4'",
    "'1AR-B-SAtr_0'",
    "'1AR-B-SAtr_1'",
    "'1AR-B-SAtr_2'",
    "'1AR-B-SAtr_3'",
    "'1AR-B-SAtr_4'",
    "'1AR-B-RO_0'",
    "'1AR-B-RO_1'",
    "'1AR-B-RO_2'",
    "'1AR-B-RO_3'",
    "'1AR-B-RO_4'",
    "'1AR-B-POtr_0'",
    "'1AR-B-POtr_1'",
    "'1AR-B-POtr_2'",
    "'1AR-B-POtr_3'",
    "'1AR-B-POtr_4'",
    "'1AR-B-SA_0'",
    "'1AR-B-SA_1'",
    "'1AR-B-SA_2'",
    "'1AR-B-SA_3'",
    "'1AR-B-SA_4'",
    "'1AR-B-ACps_0'",
    "'1AR-B-ACps_1'",
    "'1AR-B-ACps_2'",
    "'1AR-B-ACps_3'",
    "'1AR-B-ACps_4'",
    "'1AR-B-PRdo_0'",
    "'1AR-B-PRdo_1'",
    "'1AR-B-PRdo_2'",
    "'1AR-B-PRdo_3'",
    "'1AR-B-PRdo_4'",
    "'1AR-B-MYga_0'",
    "'1AR-B-MYga_1'",
    "'1AR-B-MYga_2'",
    "'1AR-B-MYga_3'",
    "'1AR-B-MYga_4'",
    "'1AR-B-PYco_0'",
    "'1AR-B-PYco_1'",
    "'1AR-B-PYco_2'",
    "'1AR-B-PYco_3'",
    "'1AR-B-PYco_4'",
    "'1AR-B-RDzz_0'",
    "'1AR-B-RDzz_1'",
    "'1AR-B-RDzz_2'",
    "'1AR-B-RDzz_3'",
    "'1AR-B-RDzz_4'",
    "'1AR-B-SOau_0'",
    "'1AR-B-SOau_1'",
    "'1AR-B-SOau_2'",
    "'1AR-B-SOau_3'",
    "'1AR-B-SOau_4'",
    "'1AR-B-ROru_0'",
    "'1AR-B-ROru_1'",
    "'1AR-B-ROru_2'",
    "'1AR-B-ROru_3'",
    "'1AR-B-ROru_4'",
    "'1AR-B-SAca_0'",
    "'1AR-B-SAca_1'",
    "'1AR-B-SAca_2'",
    "'1AR-B-SAca_3'",
    "'1AR-B-SAca_4'",
    "'1AR-B-PRsp_0'",
    "'1AR-B-PRsp_1'",
    "'1AR-B-PRsp_2'",
    "'1AR-B-PRsp_3'",
    "'1AR-B-PRsp_4'",
    "'1AR-B-ACpl_0'",
    "'1AR-B-ACpl_1'",
    "'1AR-B-ACpl_2'",
    "'1AR-B-ACpl_3'",
    "'1AR-B-ACpl_4'",
    "'1AR-B-ALgl_0'",
    "'1AR-B-ALgl_1'",
    "'1AR-B-ALgl_2'",
    "'1AR-B-ALgl_3'",
    "'1AR-B-ALgl_4'",
    "'1AR-B-SYvu_0'",
    "'1AR-B-SYvu_1'",
    "'1AR-B-SYvu_2'",
    "'1AR-B-SYvu_3'",
    "'1AR-B-SYvu_4'",
    "'1AR-B-HIrh_0'",
    "'1AR-B-HIrh_1'",
    "'1AR-B-HIrh_2'",
    "'1AR-B-HIrh_3'",
    "'1AR-B-HIrh_4'",
    "'1AR-B-FUal_0'",
    "'1AR-B-FUal_1'",
    "'1AR-B-FUal_2'",
    "'1AR-B-FUal_3'",
    "'1AR-B-FUal_4'",
    "'4DG-0_0'",
    "'4DG-0_1'",
    "'4DG-0_2'",
    "'4DG-0_3'",
    "'4DG-0_4'",
    "'4DG-0_5'",
    "'4DG-0_6'",
    "'4DG-0_7'",
    "'4DG-0_8'",
    "'4DG-0_9'",
    "'4DG-0_X'",
    "'4DL-0_0'",
    "'4DL-0_1'",
    "'4DL-0_2'",
    "'4DL-0_3'",
    "'4DL-0_4'",
    "'4DL-0_5'",
    "'4DL-0_6'",
    "'4DL-0_7'",
    "'4DL-0_8'",
    "'4DL-0_9'",
    "'4DL-0_X'",
    "'5XG-SM_0'",
    "'5XG-SM_3'",
    "'5XG-SM_1'",
    "'5XG-SM_2'",
    "'5XG-ST_0'",
    "'5XG-ST_1'",
    "'5XG-ST_2'",
    "'5XG-ST_3'",
    "'7EU_0'",
    "'7EU_1'",
    "'7EU_2'",
    "'7EU_3'",
    "'7EU_4'",
    "'7EU_5'",
    "'7EU_6'",
    "'7EU_X'",
    "'7FA_0'",
    "'7FA_1'",
    "'7FA_2'",
    "'7FA_3'",
    "'7FA_4'",
    "'7FA_5'",
    "'7FA_6'",
    "'7FA_X'",
    "'7GR-EG_1'",
    "'7GR-EG_2'",
    "'7GR-EG_3'",
    "'7GR-EG_4'",
    "'7GR-EG_X'",
    "'7GR-GI_1'",
    "'7GR-GI_2'",
    "'7GR-GI_3'",
    "'7GR-GI_4'",
    "'7GR-GI_5'",
    "'7GR-GI_X'",
    "'7JB-BA_1'",
    "'7JB-BA_2'",
    "'7JB-BA_3'",
    "'7JB-BA_4'",
    "'7JB-BA_5'",
    "'7JB-BA_6'",
    "'7JB-BA_7'",
    "'7JB-BA_8'",
    "'7JB-BA_X'",
    "'7JB-HT_X'",
    "'7JB-HT-ST_0'",
    "'7JB-HT-ST_1'",
    "'7JB-HT-SL_0'",
    "'7JB-HT-SL_1'",
    "'7RA-SJ_1'",
    "'7RA-SJ_2'",
    "'7RA-SJ_3'",
    "'7RA-SJ_4'",
    "'7RA-SJ_X'",
    "'7RA-SM_1'",
    "'7RA-SM_2'",
    "'7RA-SM_X'",
    "'7RA-BH_1'",
    "'7RA-BH_2'",
    "'7RA-BH_3'",
    "'7RA-BH_X'",
    "'7SB-HI-AAP-0_0'",
    "'7SB-HI-AAP-0_1'",
    "'7SB-HI-AAP-0_2'",
    "'7SB-HI-AAP-0_3'",
    "'7SB-HI-AAP-0_4'",
    "'7SB-HI-AAP-0_5'",
    "'7SB-HI-AAP-0_X'",
    "'7SD-0_1'",
    "'7SD-0_2'",
    "'7SD-0_X'",
    "'7SE_0'",
    "'7SE_1'",
    "'7SE_2'",
    "'7SE_3'",
    "'7SE_X'",
    "'7TK_0'",
    "'7TK_1'",
    "'7TK_2'",
    "'7TK_3'",
    "'7TK_X'",
    "'7SN-XF_0'",
    "'7SN-XF_1'",
    "'7SN-XF_2'",
    "'7SN-XF_3'",
    "'7SN-XF_4'",
    "'7SN-XF_5'",
    "'7SN-XF_6'",
    "'7SN-XF_7'",
    "'7SN-XF_8'",
    "'7SN-XF_X'",
    "'7VR-RI_1'",
    "'7VR-RI_2'",
    "'7VR-RI_3'",
    "'7VR-RI_4'",
    "'7VR-RI_5'",
    "'7VR-RI_X'",
    "'7VR-EG_1'",
    "'7VR-EG_2'",
    "'7VR-EG_3'",
    "'7VR-EG_4'",
    "'7VR-EG_X'",
    "'9TS_1'",
    "'9TS_2'",
    "'9TS_3'",
    "'9TS_4'",
    "'9TS_X'",
    "'PRFI_0'",
    "'PRFI_1'",
    "'PRFI_2'",
    "'PRFI_3'",
    "'PRFI_X'",
    "'PRPA_0'",
    "'PRPA_1'",
    "'PRPA_2'",
    "'PRPA_3'",
    "'PRPA_X'"
]