import './App.css';
import './index.css';

import Filter from './components/Filter'
import React, { useState, useEffect } from 'react';

function App() {

  const [filterurl, setFilterurl] = useState("");

  useEffect(() => {

    document.title = process.env.REACT_APP_WindowTitle;

    var hash = window.location.search.substring(1);//response_type=code bruker search

    var results = {}
    var result = hash.split('&').reduce(function (result, item) {
        var parts = item.split('=');
        results[parts[0]] = parts[1];    
        return results;
        
    }, {});

    if ((result).filtersetting){ //filtersetting er den eneste verdien som alltid må finnes dersom filteret hentes fra url
      setFilterurl((result))
    }
  }, []);

  return (
    <div className="App">
      <div className="maincontent">
        <Filter filterurl={filterurl}/>
      </div>
    </div>
  );
}

export default App;
